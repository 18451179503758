import * as React from 'react';
import { LazyImage } from './lazyImage/LazyImage';
import { createImageAspectRatios, getImageSrcAndSrcSet, ImageAspectRatios, ImageWidths, ImageHeightCalculationMethods } from './image.utils';
import { EagerImage } from './eagerImage/EagerImage';
import { OptionalAdditionalStyle } from '../../components.utils';
import { ImageWrapper } from './ImageWrapper';
import { Thumbnail } from './image.types';

export interface ImageDataProps {
  lazyLoad?: boolean;
  dataId?: string;
  imageAspectRatios?: ImageAspectRatios;
  imageHeightCalculationMethods?: ImageHeightCalculationMethods;
  imageWidths: ImageWidths;
  transparent?: boolean;
}
export type ImageProps = ImageDataProps & OptionalAdditionalStyle & Thumbnail;

export const Image: React.FunctionComponent<ImageProps> = props => {
  const { alt, host, path, aspectRatio = { x: 16, y: 9 }, additionalStyle, lazyLoad, dataId, imageHeightCalculationMethods, transparent = false, caption, cropping = null } = props;
  const { imageAspectRatios = createImageAspectRatios(aspectRatio, aspectRatio, aspectRatio), imageWidths } = props;
  const CalculatedImage = lazyLoad ? LazyImage : EagerImage;
  const { fallbackSrc, lowResUrl, srcSetLargeScreen, srcSetMediumScreen, srcSetSmallScreen } = getImageSrcAndSrcSet(imageAspectRatios, imageWidths, host, path, cropping);
  const actualAltAndTitle = alt || caption || '';

  return (
    <ImageWrapper imageAspectRatios={imageAspectRatios} additionalStyle={additionalStyle} dataId={dataId} imageHeightCalculationMethods={imageHeightCalculationMethods} transparent={transparent}>
      <CalculatedImage alt={actualAltAndTitle} title={actualAltAndTitle} fallbackSrc={fallbackSrc} lowResUrl={lowResUrl} srcSetLargeScreen={srcSetLargeScreen} srcSetMediumScreen={srcSetMediumScreen} srcSetSmallScreen={srcSetSmallScreen} />
    </ImageWrapper>
  );
};
