import React, { useRef } from 'react';
import { SlideshowBottomBar } from './SlideshowBottomBar';
import { SlideshowTopBar } from './SlideshowTopBar';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { PaddingOverride } from '../blockMutualStyle';

type SlideshowProps = {
  prevText: string;
  nextText: string;
  ofText: string;
  currentSlide: number;
  totalSlides: number;
  blockWidths: BlockWidths;
  overrideBlockPadding?: PaddingOverride;
  slidesLinks: Record<string, string>;
  experiments?: Array<string>;
  commercialTags: Array<string>;
  ownerUsername: string;
  resourceID: string | null;
};

export const SlideshowContext = React.createContext<null|number >(null);

export const Slideshow: React.FunctionComponent<SlideshowProps> = props => {
  const { nextText, totalSlides, currentSlide, children, ofText, prevText, blockWidths, overrideBlockPadding, slidesLinks, experiments, commercialTags, ownerUsername, resourceID } = props;
  const [slideNumber, setSlideNumber] = React.useState(currentSlide);
  React.useEffect(() => {
    try {
      if (window && totalSlides > 0) {
        setTimeout(() => {
          // @ts-ignore
          window.dataLayer = window.dataLayer || [];
          // @ts-ignore
          window.dataLayer.push({
            event: 'GTM data ready to GA - Slideshow',
            event_label: 'Slideshow page view',
            GA_event_action: 'Slideshow Pageview',
            slideNumber,
            isSlideshow: true,
            articleId: resourceID,
            commercialTags,
            experiments,
            ownerUsername,
            articleURL: slidesLinks[slideNumber] || window.location.href.split('?')[0],
          });
        }, 500);
        const queryParams = window.location.search;
        const currentUrl = slidesLinks[slideNumber] || window.location.href;
        window.history.replaceState(null, 'URL change', `${currentUrl}${queryParams}`);
      }
    } catch (e) {
      console.log('slideshow error', e);
    }
  }, [slidesLinks, slideNumber, totalSlides, resourceID, commercialTags, experiments, ownerUsername]);

  const sendCustomEvent = (eventName: string, eventData: any) => {
    const customEvent = new CustomEvent(eventName, { detail: eventData });
    window.dispatchEvent(customEvent);
  };

  const scrollToSlideshowTopBar = useRef(null);
  const onClickNext = () => {
    setSlideNumber(slide => slide + 1);
    sendCustomEvent('slideshowEvent', { message: 'Next button clicked', slideNumber: slideNumber + 1 });
  };

  const onClickPrev = () => {
    setSlideNumber(slide => slide - 1);
    sendCustomEvent('slideshowEvent', { message: 'Prev button clicked', slideNumber: slideNumber - 1 });
  };

  const onClickBottomNext = () => {
    onClickNext();
    // @ts-ignore
    return scrollToSlideshowTopBar.current?.scrollIntoView<null | HTMLDivElement>();
  };
  const getNextUrl = () => (slideNumber !== totalSlides ? slidesLinks[slideNumber + 1] : '');
  const getPrevUrl = () => (slideNumber > 1 ? slidesLinks[slideNumber - 1] : '');

  if (totalSlides === 0) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <SlideshowContext.Provider value={slideNumber}>
      <SlideshowTopBar
        totalSlides={totalSlides}
        nextText={nextText}
        ofText={ofText}
        prevText={prevText}
        blockWidths={blockWidths}
        overrideBlockPadding={overrideBlockPadding}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        slideNumber={slideNumber}
        scrollToSlideshowTopBar={scrollToSlideshowTopBar}
        nextUrl={getNextUrl()}
        prevUrl={getPrevUrl()}
      />
      {children}
      <SlideshowBottomBar nextText={nextText} onClick={onClickBottomNext} isDisabled={slideNumber === totalSlides} />
    </SlideshowContext.Provider>
  );
};
