import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

export enum OpenGraphTypes {
    Website='website',
    Article='article',
}
export interface OpenGraphCommonProps {
    siteName: string;
    image: string | null;
    title: string;
    pageUrl: string;
    imageHeight: number | null;
    imageWidth: number | null;
    description: string;
}

type OpenGraphProps = OpenGraphCommonProps & {type: OpenGraphTypes};


export const OpenGraph: React.FunctionComponent<OpenGraphProps> = ({ title, siteName, image, pageUrl, imageWidth, imageHeight, description, type }) => (
  <Kasda.Meta>
    <meta property="og:title" content={title.replace(/"/g, "'")} />
    <meta property="og:site_name" content={siteName} />
    {image ? <meta property="og:image" content={image} /> : null}
    <meta property="og:url" content={pageUrl} />
    {imageWidth ? <meta property="og:image:width" content={`${imageWidth}`} /> : null}
    {imageHeight ? <meta property="og:image:height" content={`${imageHeight}`} /> : null}
    <meta property="og:description" content={description.replace(/"/g, "'")} />
    <meta property="og:type" content={type} />
  </Kasda.Meta>
);
