import { mandatory } from '../../../storeFromRequestParamsValidation';
import { createPath } from './createPath.utils';

export const createMainCategory = (editionEndpoint: any) => (mainCategory: any) => {
  return {
    displayName: mandatory(mainCategory.displayName, ''),
    link: mandatory(mainCategory.path, '', createPath(editionEndpoint)),
    isActive: mandatory(mainCategory.isActive, false),
  };
};

export const createBreadCrumbs = (editionEndpoint: string, homeTranslation: string) => (mainCategory: any) => {
  return {
    homeDisplayName: homeTranslation,
    homeLink: createPath(editionEndpoint)(''),
    categoryDisplayName: mandatory(mainCategory.displayName, ''),
    categoryLink: mandatory(mainCategory.path, '', createPath(editionEndpoint)),
    isActive: mandatory(mainCategory.isActive, false),
  };
};

export const createAmpMainCategory = (editionEndpoint: any) => (mainCategory: any) => {
  return {
    displayName: mandatory(mainCategory.displayName, ''),
    link: mandatory(mainCategory.path, '', createPath(editionEndpoint)),
    isActive: mandatory(mainCategory.isActive, false),
    slug: mandatory(mainCategory.slug, ''),
  };
};

export const createBreadcrumbsWithoutMainCategory = (canonicalURL: string) => {
  const baseLink = new URL(canonicalURL);
  const paths = baseLink.pathname.split('/').filter(Boolean);
  const removeDash = (text: string) => (text && text.includes('-') && text.replace(/-/g, ' ')) || text;

  return {
    homeDisplayName: 'Home',
    homeLink: baseLink.origin,
    categoryDisplayName: removeDash(paths[0]),
    categoryLink: `${baseLink.origin}/${paths[0]}`,
    ...(paths.length >= 2 && {
      thirdLink: `${baseLink.origin}/${paths[0]}/${paths[1]}`,
      thirdDisplayName: removeDash(paths[1]),
    }),
  };
};
