import * as React from 'react';
import { createImageSrc } from '../partials/image/image.utils';
import { CardComponentDataProps } from '../partials/cards/cards.utils';
import { getAuthorsListSchema } from './GoogleNewsSchema';
import { Kasda } from '../../Kasda/Kasda';
import { getPublisherNameAndLogo } from './SISchemaUtils';

interface ItemsListSchemaProps {
  publisherName: string;
  logoUrl: string;
  itemListElement: Array<CardComponentDataProps> | null;
}

export const sortByDate = (article1: CardComponentDataProps, article2: CardComponentDataProps) => {
  const article1Time = new Date(article1.featuredAt);
  const article2Time = new Date(article2.featuredAt);
  if (article2Time > article1Time) return 1;
  if (article2Time < article1Time) return -1;
  return 0;
};

const getTopArticlesByDate = (articles: Array<CardComponentDataProps> | null) => articles && [...articles].sort(sortByDate);

export const getUniqueArticles = (topArticles: Array<CardComponentDataProps>) => {
  return topArticles.reduce((uniqueElements: Array<CardComponentDataProps>, element) => {
    if (!uniqueElements.find(item => item.id === element.id)) {
      return [...uniqueElements, element];
    }
    return uniqueElements;
  }, []);
};

const getSchema = (topArticles: Array<CardComponentDataProps> | null, publisherName: string, logoUrl: string) => ({
  '@context': 'http://schema.org',
  '@type': 'ItemList',
  itemListElement: topArticles && getUniqueArticles(topArticles).map((item, position) => {
    const imageUrl = createImageSrc(item.image.host, item.image.path, 720, { x: 16, y: 9 }, 1, item.image.cropping || null);

    const {
      publisherName: calculatedPublisherName,
      logoUrl: calculatedLogoUrl,
    } = getPublisherNameAndLogo(publisherName, logoUrl, item.articleUrl);

    return {
      '@type': 'NewsArticle',
      position: position + 1,
      url: item.articleUrl,
      headline: item.title,
      image: imageUrl,
      datePublished: item.featuredAt,
      author: getAuthorsListSchema(item.authors),
      publisher: {
        '@type': 'Organization',
        name: calculatedPublisherName,
        logo: {
          '@type': 'ImageObject',
          url: calculatedLogoUrl,
        },
      },
      articleSection: item.mainCategory && item.mainCategory.displayName,
    };
  }),
});

export const ItemsListSchema: React.FunctionComponent<ItemsListSchemaProps> = ({ itemListElement, publisherName, logoUrl }) => {
  const topArticles = getTopArticlesByDate(itemListElement);
  const scriptContent = getSchema(topArticles, publisherName, logoUrl);
  return (
    <Kasda.Script>
      <script type="application/ld+json">{JSON.stringify(scriptContent)}</script>
    </Kasda.Script>
  );
};
