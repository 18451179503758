const SI_DOMAIN = 'si.com';
const SI_HYBRID = 'www-si-com.hybrid'; // TODO: remove after testing
const QA_SI_DOMAIN = 'mmsport.minutemediabeta.com'; // TODO: remove after testing

const SI_PROPERTIES = [
  'videogames.si.com',
  'swimsuit.si.com',
  'esi.si.com',
  'highschool.si.com',
  'vault.si.com',
  'lifestyle.si.com',
  'sikids.com',
  'collectibles.si.com',
  'health.si.com',
  'pickleball.si.com',
  'news.si.com',
  'pressroom.si.com',
  'subscriptions.si.com',
  'mmsport.minutemediabeta.com', // TODO: remove this after testing
];

const propertiesForThreeRowsHeader = [
  'www.path-2-career.com',
  'www.the-wellness-way.com',
  'www.home-is-love.com',
];

const removeTrailingSlash = (endpoint: string) => {
  return endpoint && endpoint.endsWith('/') ? endpoint.substring(0, endpoint.length - 1) : endpoint;
};

export const isSiProperty = (propertyEndpoint: string) => {
  return propertyEndpoint.includes(SI_DOMAIN) || propertyEndpoint.includes(SI_HYBRID) || propertyEndpoint.includes(QA_SI_DOMAIN) || SI_PROPERTIES.includes(removeTrailingSlash(propertyEndpoint));
};

export const isPropertyForThreeRowsHeader = (propertyEndpoint: string) => {
  return propertiesForThreeRowsHeader.includes(propertyEndpoint);
};
