import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { CalculatedThumbnail } from '../image.types';
import { imageStyle } from '../image.utils';
import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '../../../../mediaQueries.const';

export const EagerImage: React.FunctionComponent<CalculatedThumbnail> = props => {
  const { fallbackSrc, alt, title, srcSetLargeScreen, srcSetMediumScreen, srcSetSmallScreen } = props;
  return (
    <picture className={css(imageStyle.base)}>
      <source srcSet={srcSetSmallScreen} media={`(max-width: ${SMALL_SCREEN_SIZE}px)`} />
      <source srcSet={srcSetMediumScreen} media={`(max-width: ${MEDIUM_SCREEN_SIZE}px)`} />
      <source srcSet={srcSetLargeScreen} media={`(min-width: ${LARGE_SCREEN_SIZE}px)`} />
      <img
        className={css(imageStyle.base)}
        src={fallbackSrc}
        alt={alt}
        title={title}
      />
    </picture>
  );
};
