import {
  STANDARD_PROPS,
  NAME_TO_ID,
  SPORTRADAR_PROPS_MAPPING,
  TEAM_ID_KEYS,
  EXCLUDED_LIST,
  SPORTRADAR_MATCH_MAPPING,
  WIDGETS_WITH_SR_MATCH_ID,
  LEAGUE,
  MATCH_STATUS,
  TABS,
} from './sportradarMapping.const';

import { SportradarProp, SportradarPropsType } from './sportradar.types';

export const buildSportradarProps = (
  config: SportradarProp[],
  data: SportradarPropsType,
  index: number,
) => Object.keys(data).map(key => {
  if (EXCLUDED_LIST.includes(key)) return '';
  if (typeof config[index][key] === 'string') return `${key}: "${config[index][key]}"`;
  return `${key}: ${config[index][key]}`;
})
  .filter(Boolean)
  .join(', ');

export const buildSportradar = (
  mappedProps: SportradarProp,
  sportradarProps: SportradarProp,
) => {
  const data: SportradarProp = { ...mappedProps, ...STANDARD_PROPS };
  return Object.keys(data).reduce((acc: SportradarProp, cur) => {
    if (cur === 'teamTitle') {
      // Add team name to title
      data.title = `${sportradarProps.team ? sportradarProps.team : ''} ${data.title}`;
    }

    if (
      sportradarProps.league === LEAGUE.NFL
      && cur === 'matchId'
      && WIDGETS_WITH_SR_MATCH_ID.includes(sportradarProps.widgetId)
    ) {
      acc[cur] = sportradarProps.srMatchId;
    }

    if (TEAM_ID_KEYS.includes(cur)) {
      acc[cur] = sportradarProps.teamUid;
    } else {
      acc[cur] = sportradarProps[cur] || data[cur];
    }

    return acc;
  }, {});
};

export const buildSportradarList = (sportradarProps: SportradarPropsType) => {
  const {
    league,
    matchStatus,
    tab = 'game',
    teamAId,
    teamA,
    teamBId,
    teamB,
    seasonId,
    matchId,
    srMatchId,
  } = sportradarProps;

  if (!league || !matchStatus || !SPORTRADAR_MATCH_MAPPING[league]) {
    return console.error('Missing league or matchStatus in sportradarProps');
  }

  const widgetList = SPORTRADAR_MATCH_MAPPING[league][matchStatus][tab];

  let data: SportradarPropsType = { seasonId, matchId, srMatchId, league };

  return widgetList?.map((widget: string, index: number) => {
    const widgetId = NAME_TO_ID[widget];
    const srUniqueId = sportradarProps?.teamUid
      || sportradarProps?.srMatchId
      || sportradarProps?.matchId
      || '';
    const dataId = `${widgetId}-${index}-${srUniqueId}`;

    if (widgetList.indexOf(widget) === index) {
      data = { ...data, dataId: `${dataId}-A`, teamUid: teamAId, team: teamA };
    } else {
      data = { ...data, dataId: `${dataId}-B`, teamUid: teamBId, team: teamB };
    }

    return buildSportradar(SPORTRADAR_PROPS_MAPPING[widgetId], {
      dataId,
      ...data,
      widgetId: widget,
    });
  });
};

export const buildSportradarFromName = (
  name: string,
  sportradarProps: SportradarPropsType,
) => {
  const id = NAME_TO_ID[name];
  const srUniqueId = sportradarProps?.teamUid || sportradarProps?.matchId || '';
  const dataId = `${id}-${srUniqueId}`;
  const data = {
    ...sportradarProps,
    widgetId: name,
    dataId,
  };

  return [buildSportradar(SPORTRADAR_PROPS_MAPPING[id], data)];
};

export const configureLeague = (canonical: string) => {
  const baseLink = new URL(canonical);
  const paths = baseLink.pathname.split('/').filter(Boolean);
  const supportedLeagues: string[] = [LEAGUE.NFL, LEAGUE.NBA, LEAGUE.MLB, LEAGUE.COLLEGE, LEAGUE.NHL, LEAGUE.WNBA];
  let league = paths.find(path => supportedLeagues.includes(path)) || '';

  if (league === LEAGUE.COLLEGE) {
    const ncaaf = paths.includes('college-football') && LEAGUE.NCAAF;
    const ncaab = paths.includes('college-basketball') && LEAGUE.NCAAB;
    league = ncaaf || ncaab || '';
  }

  return league;
};

export function tickerCallback(target: string, obj: any) {
  if (target === 'match') {
    const {
      awayTeamUid,
      homeTeamUid,
      matchStatus,
      matchId,
      seasonId,
      sportId,
      usSport,
    } = obj;
    let params;

    if (sportId > 7 && usSport === 5) {
      const { awayTeamId, homeTeamId, srMatchId } = obj;
      params = new URLSearchParams({
        awayTeamUid: awayTeamId.replace('sd:team:', ''),
        homeTeamUid: homeTeamId.replace('sd:team:', ''),
        matchStatus,
        matchId: matchId.replace('sd:match:', ''),
        srMatchId,
        seasonId,
        sportId: usSport,
      });
    } else {
      params = new URLSearchParams({
        awayTeamUid,
        homeTeamUid,
        matchStatus,
        matchId,
        seasonId,
        sportId,
        usSport,
      });
    }

    window.location.href = `/game?${params.toString()}`;
  }
}

export const configureMatchColumnLength = (widgetListLength: number, tab?: string, matchStatus?: string, league?: string) => {
  if (tab === TABS.BETTING) {
    return 2;
  }

  if (
    ((league === LEAGUE.MLB && matchStatus === MATCH_STATUS.POST)
      || (league === LEAGUE.SOCCER && matchStatus === MATCH_STATUS.PRE))
    && tab === TABS.GAME
  ) {
    return 2;
  }

  if (league === LEAGUE.NHL && matchStatus === MATCH_STATUS.PRE) {
    return 6;
  }

  if (
    (league === LEAGUE.NHL && matchStatus === MATCH_STATUS.POST)
    || (league === LEAGUE.MLB && matchStatus === MATCH_STATUS.PRE)
  ) {
    return 5;
  }

  if (
    (league === LEAGUE.NFL
      || league === LEAGUE.NCAAB
      || league === LEAGUE.WNBA
      || league === LEAGUE.NCAAF)
    && matchStatus === MATCH_STATUS.POST
    && tab === TABS.GAME
  ) {
    return 3;
  }

  if (league === LEAGUE.MLB && matchStatus === MATCH_STATUS.LIVE && tab === TABS.GAME) {
    return 3;
  }

  if (widgetListLength >= 4) {
    return 4;
  }

  return widgetListLength;
};
