export const TITLE = 'About';

export const SUB_TITLES = {
  MEET_TEAM: 'Meet The Team',
  WRITE: 'Write For Us',
  ABOUT_FANSIDED: 'About FanSided',
  CONTACT: 'Advertise/Contact Us',
};

export const LINKS = {
  OPENINGS: {
    LINK: 'https://openings.fansided.com/#current-openings',
    TEXT: 'check out our openings page',
  },
  PROPOSAL: {
    LINK: 'https://springboard.fansided.com/',
    TEXT: 'submit a proposal',
  },
  CONTACT_LINK: {
    TEXT: 'contact@fansided.com',
  },
  LEGAL: {
    LINK: 'https://fansided.com/website-disclaimer/',
    TEXT: 'Legal Disclaimer',
  },
};

export const TEXT = {
  WRITE:
    "We aren't called FanSided for nothing. Our network of sports, entertainment and lifestyle sites are powered by fans that want to cover their passions! Whether you are just looking to get your start in online media or you want to contribute to your favorite site as a hobby, FanSided wants to hear from you. Whatever your goal, FanSided can help your voice be heard. If you are interested in writing for any of our sites, please be sure to ",
  ABOUT_FANSIDED:
    'The idea of FanSided was born in 2007, when two brothers felt that their favorite team, the Kansas City Chiefs, was being under-covered by the mainstream media. Taking matters into their own hands, they launched Arrowhead Addict, a move that would spawn the creation of FanSided in 2009, a network of over 300+ fan-powered unique sports, entertainment and lifestyle sites dedicated to team-specific, sport-specific, genre-specific, and fanbase-specific coverage. From sports, movies and TV to lifestyle and tech, FanSided has you covered.',
  CONTACT: {
    SUB_TITLE: "Let's stay in touch.",
    AD: "If you'd like to advertise with us, partner with us or have feedback, please contact us at ",
    WRITE_US:
      ' or write us at: Pro Sportority (Israel) Ltd. d/b/a Minute Media, 8 Yitzhak Sadeh St., Acro Tower, Tel Aviv–Jaffa 6777508, Israel',
  },
};
