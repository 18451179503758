import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { Thumbnail } from '../../../partials/image/image.types';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';
import { P, semanticCSS } from '../../../../typography/semanticTags';
import { AuthorImage } from '../AuthorImage/AuthorImage';
import { createImageWidths } from '../../../partials/image/image.utils';
import { TwitterIcon } from '../../../partials/icon/icons';
import { Icon } from '../../../partials/icon/Icon';
import { AdditionalStyleSheet } from '../../../components.utils';
import { useTheme } from '../../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { ellipsify } from '../../../../typography/ellipsify';

export const componentConfigurationKey = 'authorBio';

interface AuthorBioThemeProps {
  colors: Colors;
  linkInTextColor: string;
}

export interface AuthorBioProps {
  image?: Thumbnail;
  name: string;
  socialNickname?: string;
  socialLink?: string;
  bio: string;
}

const getStyle = (colors: Colors, linkInTextColor: string) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    [MEDIA_BREAKPOINTS.large]: {
      width: '640px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: '100%',
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: '100%',
    },
  },
  detailsWrapper: {
    marginLeft: '15px',
  },
  img: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '100%',
    boxSizing: 'border-box',
    width: '70px',
    height: '70px',
  },
  twitterButton: {
    width: 'fit-content',
    height: '36px',
    borderRadius: '18px',
    backgroundColor: colors.darkGrey,
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 20px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colors.primary,
    },
  },
  bio: {
    margin: '5px 0px 15px 0px',
    color: colors.darkGrey,
    ...ellipsify(3).style,
  },
  name: {
    color: colors.darkGrey,
  },
  link: {
    color: linkInTextColor,
  },
});

const getIconStyle = (colors: Colors) => (StyleSheet.create({
  style: {
    fill: colors.white,
    marginRight: '10px',
  },
})) as AdditionalStyleSheet;

export const AuthorBio: React.FunctionComponent<AuthorBioProps> = props => {
  const {
    image,
    name,
    bio,
    socialNickname,
    socialLink,
  } = props;

  const {
    colors,
    linkInTextColor,
  } = useTheme<AuthorBioThemeProps>(componentConfigurationKey);

  const styles = getStyle(colors, linkInTextColor);

  const onTwitterButtonClick = () => {
    window.open(socialLink, '_blank', 'noopener');
    return false;
  };

  const tinyFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    fontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    fontSizeLarge: TYPOGRAPHY_SIZE.TINY,
  };

  const bigFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.BIG,
    fontSizeMedium: TYPOGRAPHY_SIZE.BIG,
    fontSizeLarge: TYPOGRAPHY_SIZE.BIG,
  };

  return (
    <ErrorBoundary>
      <div className={css(styles.wrapper)}>
        {image && (
          <AuthorImage
            image={image}
            wrapperStyles={styles.img}
            widthSizes={{ small: 70, medium: 70, large: 70 }}
            imageWidths={createImageWidths(180, 180, 180)}
          />
        )}
        <div className={css(styles.detailsWrapper)}>
          <P
            styles={semanticCSS(styles.name)}
            {...tinyFontSizes}
          >
            {name?.toUpperCase()}
          </P>
          <P
            styles={semanticCSS(styles.bio)}
            {...bigFontSizes}
          >
            {bio}
          </P>
          {socialNickname ? (
            <button
              className={css(styles.twitterButton)}
              type="button"
              onClick={onTwitterButtonClick}
            >
              <Icon
                icon={TwitterIcon}
                additionalStyle={getIconStyle(colors)}
                height={16}
                width={16}
                hasButton={false}
              />
              <P {...tinyFontSizes}>{`Follow ${socialNickname}`}</P>
            </button>
          ) : null}
        </div>
      </div>
    </ErrorBoundary>
  );
};
