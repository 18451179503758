import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { ImageStyleSheet } from './image.types';
import { useTheme } from '../../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey as articleBlockComponentConfigurationKey } from '../../articleComponents/ArticleBlock.theme';

export type ImageWidth = 16 | 50 | 80 | 110 | 150 | 180 | 335 | 360 | 540 | 620 | 720 | 1080 | 1200 | 1440;
type Dpr = 1 | 2 | 3;

export interface ImageWidths {
  small: ImageWidth;
  medium: ImageWidth;
  large: ImageWidth;
}

export interface CroppingData {
  x: number;
  y: number;
  width: number;
  height: number;
}

const calculateImageUrl: (host: string, path: string, manipulation?: string) => string = (host, path, manipulation?) => `${host + manipulation}/${path}`;
const isHighDprInLargeImage = (width: number, dpr: number) => width >= 600 && dpr > 2;

const addCroppingParamsToPath = (path: string, croppingData: CroppingData | null) => {
  if (croppingData) {
    return `c_crop,w_${croppingData.width},h_${croppingData.height},x_${croppingData.x},y_${croppingData.y}/${path}`;
  }
  return path;
};

export const createImageSrc = (host: string, path: string, width: ImageWidth, aspectRatio: RATIO, dpr: Dpr, croppingData: CroppingData | null): string => {
  if (isHighDprInLargeImage(width, dpr)) {
    return createImageSrc(host, path, width, aspectRatio, 2, croppingData);
  }

  return calculateImageUrl(host, path, addCroppingParamsToPath(`c_fill,w_${width * dpr},ar_${aspectRatio.x}:${aspectRatio.y},f_auto,q_auto,g_auto`, croppingData));
};

export const getImageSrcAndSrcSet = (imageAspectRatios: ImageAspectRatios, imageWidths: ImageWidths, host: string, path: string, cropping: CroppingData | null) => {
  const encodedPathForSrcset = host && host.includes('minutemediacdn') ? encodeURIComponent(path) : path;

  return {
    srcSetSmallScreen: `${createImageSrc(host, encodedPathForSrcset, imageWidths.small, imageAspectRatios.small, 1, cropping)} 1x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.small, imageAspectRatios.small, 2, cropping)} 2x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.small, imageAspectRatios.small, 3, cropping)} 3x`,
    srcSetMediumScreen: `${createImageSrc(host, encodedPathForSrcset, imageWidths.medium, imageAspectRatios.medium, 1, cropping)} 1x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.medium, imageAspectRatios.medium, 2, cropping)} 2x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.medium, imageAspectRatios.medium, 3, cropping)} 3x`,
    srcSetLargeScreen: `${createImageSrc(host, encodedPathForSrcset, imageWidths.large, imageAspectRatios.large, 1, cropping)} 1x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.large, imageAspectRatios.large, 2, cropping)} 2x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.large, imageAspectRatios.large, 3, cropping)} 3x`,
    lowResUrl: createImageSrc(host, path, 16, { x: 16, y: 9 }, 1, cropping),
    fallbackSrc: createImageSrc(host, path, imageWidths.medium, imageAspectRatios.medium, 1, cropping),
  };
};

export const imageStyle = StyleSheet.create({
  base: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    minWidth: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    transition: 'opacity 0.2s ease-in-out',
    objectFit: 'cover',
    minHeight: '100%',
  },
}) as ImageStyleSheet;

export const getImageCaption = (credit: string | undefined, caption: string | undefined): string => {
  return [caption, credit].filter(value => {
    return typeof value === 'string' && value.trim() !== '';
  }).join(' | ');
};

export const createImageWidths = (small: ImageWidth, medium: ImageWidth, large: ImageWidth) => ({
  small,
  medium,
  large,
});

export type ImageHeightCalculationMethodsTypes = 'ASPECT_RATIO' | '100%_HEIGHT';

export interface ImageHeightCalculationMethods {
  small: ImageHeightCalculationMethodsTypes;
  medium: ImageHeightCalculationMethodsTypes;
  large: ImageHeightCalculationMethodsTypes;
}

export const createImageHeightCalculationMethods = (small: ImageHeightCalculationMethodsTypes, medium: ImageHeightCalculationMethodsTypes, large: ImageHeightCalculationMethodsTypes) => ({
  small,
  medium,
  large,
});

export interface ImageAspectRatios {
  small: RATIO;
  medium: RATIO;
  large: RATIO;
}

export const createImageAspectRatios = (small: RATIO, medium: RATIO, large: RATIO) => ({
  small,
  medium,
  large,
});

const getCreditStyle = (linkColor: string) => StyleSheet.create({
  style: {
    color: linkColor,
    textDecoration: 'none',
  },
}).style;

export const useTransformCreditHTML = (credit: string | undefined) => {
  const { creditLinkColor } = useTheme<ArticleBlockThemeProps>(articleBlockComponentConfigurationKey);
  const styleForCredit = css(getCreditStyle(creditLinkColor));
  if (!credit) {
    return '';
  }
  return credit
    .replace(/<a/g, `<a class="${styleForCredit}"`)
    .replace(/div/g, 'span');
};
