export const getPublisherNameAndLogo = (publisherName: string, logoUrl: string, pageUrl: string) => {
  const finalPageUrl = pageUrl || '';
  const SIPattern = /(?:www\.si\.com|www-si-com)/;
  const isSportsIllustrated = SIPattern.test(finalPageUrl);
  const SILogoUrl = 'https://www.si.com/.image/c_fit%2Ccs_srgb%2Ch_60%2Cq_auto:good%2Cw_600/MTY3NDcxMDIwMjQ3MDk4OTky/si_logo_60x600_red.png';

  return {
    publisherName: isSportsIllustrated ? 'Sports Illustrated' : publisherName,
    logoUrl: isSportsIllustrated ? SILogoUrl : logoUrl,
  };
};
