import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { PostAside } from './PostAside';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { useTheme } from '../../theming/useTheme';
import { BaseUnit } from '../../theming/baseUnitDefinition';

interface PostContentWithAsideProps {
  adId1: string | null;
  adId2: string | null;
  adOnSuccess1: (() => void) | null;
  adOnSuccess2: (() => void) | null;
}

const getStyles = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '660px 300px',
        gridColumnGap: `${baseUnit * 4}px`,
        paddingLeft: `${baseUnit * 4}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'block',
        paddingLeft: '90px',
        paddingRight: '20px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'block',
        padding: 0,
      },
    },
  });
};

export const PostContentWithAside: React.FunctionComponent<PostContentWithAsideProps> = props => {
  const { adId1, adId2, children, adOnSuccess2, adOnSuccess1 } = props;
  const { baseUnit } = useTheme();
  const styles = getStyles(baseUnit);

  return (
    <div className={css(styles.wrapper)}>
      <main>
        {children}
      </main>
      <PostAside adId1={adId1} adId2={adId2} adOnSuccess1={adOnSuccess1} adOnSuccess2={adOnSuccess2} />
    </div>
  );
};
