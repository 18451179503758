import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { useTheme } from '../../theming/useTheme';
import { Ad } from '../ads/Ad';
import { AdditionalStyleSheet } from '../components.utils';
import { HeaderHeightsPerBreakpoint } from '../components/navigation/header/oldHeader/oldHeader.utils';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { useHeaderHeights } from '../components/navigation/header/useHeaderHeights';

interface PostSidebarThemeProps {
  baseUnit: BaseUnit;
}

interface PostSideBarDataProps {
  adId1: string | null;
  adId2: string | null;
  adOnSuccess1: (() => void) | null;
  adOnSuccess2: (() => void) | null;
  disableDefaultWrapperPadding?: boolean;
  postAsideChildren?: React.ReactNode;
}

const createStyle = (baseUnit: BaseUnit, disableDefaultWrapperPadding: boolean) => {
  return StyleSheet.create({
    sidebar: {
      gridRowEnd: 'span 2',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowEnd: 'span 2',
        padding: disableDefaultWrapperPadding ? 'auto' : `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
  });
};

const createAdditionalStyleForStickyAd = (headerHeights: HeaderHeightsPerBreakpoint, baseUnit: BaseUnit) => {
  return StyleSheet.create({
    style: {
      position: 'sticky',
      paddingTop: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        top: headerHeights.large * baseUnit,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        top: headerHeights.medium * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        top: headerHeights.small * baseUnit,
      },
    },
  }) as AdditionalStyleSheet;
};

export const PostAside: React.FunctionComponent<PostSideBarDataProps> = props => {
  const { adId1, adId2, adOnSuccess1, adOnSuccess2, disableDefaultWrapperPadding = false, postAsideChildren } = props;
  const { baseUnit } = useTheme<PostSidebarThemeProps>();
  const style = createStyle(baseUnit, disableDefaultWrapperPadding);
  const headerHeights = useHeaderHeights();

  return (
    <aside className={css(style.sidebar)}>
      <Ad id={adId1} onSuccess={adOnSuccess1} />
      {postAsideChildren || null}
      <Ad
        id={adId2}
        onSuccess={adOnSuccess2}
        additionalStyle={createAdditionalStyleForStickyAd(headerHeights, baseUnit)}
      />
    </aside>
  );
};
