export enum LEAGUE {
  NBA = 'nba',
  MLB = 'mlb',
  NFL = 'nfl',
  NHL = 'nhl',
  WNBA = 'wnba',
  NCAAF = 'ncaaf',
  NCAAB = 'ncaab',
  SOCCER = 'soccer',
  COLLEGE = 'college',
}

export enum MATCH_STATUS {
  POST = 'post',
  PRE = 'pre',
  LIVE = 'live',
}

export enum TABS {
  GAME = 'game',
  BETTING = 'betting',
}

export enum SPORTRADAR_NAMES {
  NFL_BETTING_STATS = 'us.betting.match.nfl.pregameBettingStatistics',
  NFL_WIN_PROBABILITY = 'us.match.nfl.winProbability',
  NFL_PROBABILITY = 'us.match.nfl.probabilities',
  NFL_PLAY_BY_PLAY = 'us.match.nfl.playByPlay',
  NFL_BOXSCORE = 'us.match.nfl.boxScore',
  NFL_GAME_SUMMARY = 'us.match.nfl.summary',
  NFL_STANDINGS_SLIM = 'us.season.nfl.standingsSlim',
  COACHS_CORNER = 'us.match.nfl.coachesBooth',
  NFL_SCOREBOARD = 'us.match.nfl.scoreboard',
  NFL_INJURIES = 'us.season.nfl.injuries',
  NFL_LEADERS = 'us.match.nfl.leaders',
  NFL_TEAM_HEADER = 'us.team.nfl.header',
  NFL_RECENT_GAMES = 'us.team.nfl.recentGames',
  NFL_TEAM_LEADERS = 'us.season.nfl.teamLeaders',
  NHL_BOXSCORE = 'us.match.nhl.boxScore',
  NHL_STANDINGS_SLIM = 'us.season.nhl.standingsSlim',
  NHL_TEAM_STATS = 'us.match.nhl.teamStats',
  NHL_RECENT_GAMES = 'us.team.nhl.recentGames',
  NHL_RANKS = 'us.season.nhl.teamRanks',
  NHL_SCOREBOARD = 'us.match.scoreboard',
  SOCCER_SCOREBOARD = 'us.match.scoreboard',
  NHL_GAME_TRACKER = 'us.match.nhl.gameTracker',
  NHL_PLAY_BY_PLAY = 'us.match.nhl.playByPlay',
  NHL_TEAM_LEADERS = 'us.season.nhl.teamLeaders',
  WNBA_RECENT_GAMES = 'us.team.wnba.recentGames',
  WNBA_STANDINGS_SLIM = 'us.season.wnba.standingsSlim',
  PITCHER_COMPARISON = 'us.season.mlb.pitcherComparison',
  MLB_SCOREBOARD = 'us.match.mlb.scoreboard',
  MLB_PLAY_BY_PLAY = 'us.match.mlb.playByPlay',
  MLB_STANDINGS_SLIM = 'us.season.mlb.standingsSlim',
  MLB_LINEUPS = 'us.match.mlb.lineups',
  MLB_BOXSCORE = 'us.match.mlb.boxScore',
  MLB_GAME_TRACKER = 'us.match.mlb.gameTracker',
  MLB_RECENT_GAMES = 'us.team.mlb.recentGames',
  MLB_RANKS = 'us.team.mlb.statsdash',
  MLB_TEAM_LEADERS = 'us.season.mlb.teamleaders',
  MLB_STATS = 'us.betting.match.mlb.pregameBettingStatistics',
  NBA_SCOREBOARD = 'us.match.nba.scoreboard',
  NBA_COMPARISON = 'us.team.nba.comparison',
  NBA_STATISTICS = 'us.match.nba.statistics',
  NBA_TEAM_STATS = 'us.match.nba.teamStats',
  NBA_STANDINGS_SLIM = 'us.season.nba.standingsslim',
  NBA_RECENT_GAMES = 'us.team.nba.recentGames',
  NBA_BOXSCORE = 'us.match.nba.boxScore',
  NBA_PLAY_BY_PLAY = 'us.match.nba.playByPlay',
  NBA_LINESCORE = 'us.match.linescore',
  NBA_RANKS = 'us.season.nba.teamRanks',
  WNBA_RANKS = 'us.season.nba.teamRanks',
  LEADERS = 'us.match.leaders',
  INJURIES = 'us.season.injuries',
  NBA_INJURIES = 'us.season.injuries',
  MLB_INJURIES = 'us.season.injuries',
  NHL_INJURIES = 'us.season.injuries',
  WNBA_INJURIES = 'us.season.injuries',
  LIVE_MATCH_TRACKER = 'us.match.lmt',
  WIN_PROBABILITY = 'us.match.winProbability',
  TICKER = 'us.common.scoreTicker',
  ODDS = 'us.match.odds',
  BETTING_STATS = 'us.betting.match.pregameBettingStatistics',
  TEAM_HEADER = 'us.team.header',
  NHL_TEAM_HEADER = 'us.team.header',
  NBA_TEAM_HEADER = 'us.team.header',
  MLB_TEAM_HEADER = 'us.team.header',
  WNBA_TEAM_HEADER = 'us.team.header',
  NCAAF_TEAM_HEADER = 'us.team.header',
  NCAAB_TEAM_HEADER = 'us.team.header',
  NCABB_BOXSCORE = 'us.match.ncaab.boxScore',
  TEAM_MATCH_SMALL = 'us.match.scoreSmall',
  NBA_TEAM_LEADERS = 'us.season.nba.teamleaders',
  WNBA_TEAM_LEADERS = 'us.season.nba.teamleaders',
  GOLF_LEADERBOARD = 'us.event.golf.leaderboard',
  NCAAF_STANDINGS_SLIM = 'us.season.ncaaf.standingsSlim',
  NCAAF_RECENT_GAMES = 'us.team.ncaaf.recentGames',
  NCAAF_TEAM_LEADERS = 'us.season.ncaaf.teamLeaders',
  NCAAF_RANKS = 'us.season.ncaaf.teamRanks',
  NCAAF_SCHEDULE = 'us.team.ncaaf.schedule',
  NCAAB_RECENT_GAMES = 'us.team.ncaab.recentGames',
  NCAAB_STANDINGS_SLIM = 'us.season.ncaab.standingsSlim',
  NCAAB_TEAM_LEADERS = 'us.season.ncaab.teamLeaders',
  NCAAB_TEAM_STATS = 'us.match.ncaab.teamStats',
  NCAAB_RANKS = 'us.season.ncaab.teamRanks',
  NCAAB_SCOREBOARD = 'us.match.ncaab.scoreboard',
  NCAAB_BOXSCORE = 'us.match.ncaab.boxScore',
  NCAAB_PLAY_BY_PLAY = 'us.match.ncaab.playByPlay',
  NCAA_LEADERS = 'us.match.ncaa.leaders',
  NCAA_ODDS = 'us.match.ncaa.odds',
  NCAAB_STATS = 'us.betting.match.ncaab.pregameBettingStatistics',
  SOCCER_MOMENTUM = 'us.match.soccer.momentum',
  SOCCER_TIMELINE = 'us.match.soccer.verticalTimeline',
  SOCCER_LINEUPS = 'us.match.soccer.lineups',
  SOCCER_RANKS = 'us.season.soccer.teamRanks',
  SOCCER_RECENT_GAMES = 'us.team.soccer.recentGames',
  SOCCER_COMPARISON = 'us.team.soccer.comparison',
  SOCCER_STATS = 'us.match.soccer.statistics',
  SOCCER_SCORING_PROBABILITY = 'us.match.soccer.scoringProbabilityPerPeriod',
  SOCCER_HEAD_TO_HEAD = 'us.match.soccer.headToHead',
  SOCCER_PLAYER_COMPARISON = 'us.betting.match.soccer.playerComparison',
  NCAAF_SCOREBOARD = 'us.match.ncaaf.scoreboard',
  NCAAF_STATS = 'us.betting.match.ncaaf.pregameBettingStatistics',
  NCAAF_BOXSCORE = 'us.match.ncaaf.boxScore',
  NCAAF_PLAY_BY_PLAY = 'us.match.ncaaf.playByPlay',
  NCAAF_PLAYER_COMPARISON = 'us.match.ncaaf.playerComparison',
}

export const EXCLUDED_LIST = [
  'title',
  'dataId',
  'widgetId',
  'widget',
  'language',
];

export const NAME_TO_ID: any = {
  'us.betting.match.mlb.pregameBettingStatistics': 'MLB_STATS',
  'us.betting.match.ncaaf.pregameBettingStatistics': 'NCAAF_STATS',
  'us.match.ncaaf.boxScore': 'NCAAF_BOXSCORE',
  'us.match.ncaaf.playerComparison': 'NCAAF_PLAYER_COMPARISON',
  'us.betting.match.soccer.playerComparison': 'SOCCER_PLAYER_COMPARISON',
  'us.match.soccer.headToHead': 'SOCCER_HEAD_TO_HEAD',
  'us.match.soccer.scoringProbabilityPerPeriod': 'SOCCER_SCORING_PROBABILITY',
  'us.match.soccer.statistics': 'SOCCER_STATS',
  'us.season.soccer.teamRanks': 'SOCCER_RANKS',
  'us.team.soccer.comparison': 'SOCCER_COMPARISON',
  'us.team.soccer.recentGames': 'SOCCER_RECENT_GAMES',
  'us.match.soccer.lineups': 'SOCCER_LINEUPS',
  'us.match.soccer.verticalTimeline': 'SOCCER_TIMELINE',
  'us.match.soccer.momentum': 'SOCCER_MOMENTUM',
  'us.betting.match.ncaab.pregameBettingStatistics': 'NCAAB_STATS',
  'us.match.ncaa.odds': 'NCAA_ODDS',
  'us.match.ncaa.leaders': 'NCAA_LEADERS',
  'us.match.ncaab.playByPlay': 'NCAAB_PLAY_BY_PLAY',
  'us.match.ncaab.boxScore': 'NCAAB_BOXSCORE',
  'us.match.ncaab.teamStats': 'NCAAB_TEAM_STATS',
  'us.match.ncaab.scoreboard': 'NCAAB_SCOREBOARD',
  'us.betting.match.nfl.pregameBettingStatistics': 'NFL_BETTING_STATS',
  'us.match.nfl.winProbability': 'NFL_WIN_PROBABILITY',
  'us.match.nfl.probabilities': 'NFL_PROBABILITY',
  'us.match.nfl.playByPlay': 'NFL_PLAY_BY_PLAY',
  'us.match.nfl.boxScore': 'NFL_BOXSCORE',
  'us.match.nfl.scoreboard': 'NFL_SCOREBOARD',
  'us.match.nfl.summary': 'NFL_GAME_SUMMARY',
  'us.season.nfl.standingsSlim': 'NFL_STANDINGS_SLIM',
  'us.match.nfl.coachesBooth': 'COACHS_CORNER',
  'us.match.nfl.leaders': 'NFL_LEADERS',
  'us.team.nfl.header': 'NFL_TEAM_HEADER',
  'us.season.nfl.teamLeaders': 'NFL_TEAM_LEADERS',
  'us.team.nfl.recentGames': 'NFL_RECENT_GAMES',
  'us.season.nfl.injuries': 'NFL_INJURIES',
  'us.match.nhl.boxScore': 'NHL_BOXSCORE',
  'us.season.nhl.standingsSlim': 'NHL_STANDINGS_SLIM',
  'us.match.nhl.teamStats': 'NHL_TEAM_STATS',
  'us.team.nhl.recentGames': 'NHL_RECENT_GAMES',
  'us.season.nhl.teamRanks': 'NHL_RANKS',
  'us.match.nhl.gameTracker': 'NHL_GAME_TRACKER',
  'us.match.nhl.playByPlay': 'NHL_PLAY_BY_PLAY',
  'us.match.scoreboard': 'NHL_SCOREBOARD',
  'us.season.nhl.teamLeaders': 'NHL_TEAM_LEADERS',
  'us.season.mlb.pitcherComparison': 'PITCHER_COMPARISON',
  'us.match.mlb.scoreboard': 'MLB_SCOREBOARD',
  'us.match.mlb.playByPlay': 'MLB_PLAY_BY_PLAY',
  'us.match.mlb.lineups': 'MLB_LINEUPS',
  'us.team.mlb.statsdash': 'MLB_RANKS',
  'us.season.mlb.teamleaders': 'MLB_TEAM_LEADERS',
  'us.season.mlb.standingsSlim': 'MLB_STANDINGS_SLIM',
  'us.match.mlb.boxScore': 'MLB_BOXSCORE',
  'us.match.mlb.gameTracker': 'MLB_GAME_TRACKER',
  'us.team.mlb.recentGames': 'MLB_RECENT_GAMES',
  'us.match.nba.scoreboard': 'NBA_SCOREBOARD',
  'us.match.nba.statistics': 'NBA_STATISTICS',
  'us.match.nba.teamStats': 'NBA_TEAM_STATS',
  'us.team.nba.comparison': 'NBA_COMPARISON',
  'us.season.nba.standingsslim': 'NBA_STANDINGS_SLIM',
  'us.team.nba.recentGames': 'NBA_RECENT_GAMES',
  'us.match.nba.boxScore': 'NBA_BOXSCORE',
  'us.team.wnba.recentGames': 'WNBA_RECENT_GAMES',
  'us.season.wnba.standingsSlim': 'WNBA_STANDINGS_SLIM',
  'us.match.nba.playByPlay': 'NBA_PLAY_BY_PLAY',
  'us.match.winProbability': 'WIN_PROBABILITY',
  'us.match.linescore': 'NBA_LINESCORE',
  'us.common.scoreTicker': 'TICKER',
  'us.betting.match.pregameBettingStatistics': 'BETTING_STATS',
  'us.match.leaders': 'LEADERS',
  'us.season.injuries': 'INJURIES',
  'us.match.odds': 'ODDS',
  'us.match.lmt': 'LIVE_MATCH_TRACKER',
  'us.team.header': 'TEAM_HEADER',
  'us.match.scoreSmall': 'TEAM_MATCH_SMALL',
  'us.season.nba.teamleaders': 'NBA_TEAM_LEADERS',
  'us.season.nba.teamRanks': 'NBA_RANKS',
  'us.event.golf.leaderboard': 'GOLF_LEADERBOARD',
  'us.season.ncaaf.standingsSlim': 'NCAAF_STANDINGS_SLIM',
  'us.team.ncaaf.recentGames': 'NCAAF_RECENT_GAMES',
  'us.season.ncaaf.teamLeaders': 'NCAAF_TEAM_LEADERS',
  'us.season.ncaaf.teamRanks': 'NCAAF_RANKS',
  'us.team.ncaaf.schedule': 'NCAAF_SCHEDULE',
  'us.team.ncaab.recentGames': 'NCAAB_RECENT_GAMES',
  'us.season.ncaab.standingsSlim': 'NCAAB_STANDINGS_SLIM',
  'us.season.ncaab.teamLeaders': 'NCAAB_TEAM_LEADERS',
  'us.season.ncaab.teamRanks': 'NCAAB_RANKS',
  'us.match.ncaaf.scoreboard': 'NCAAF_SCOREBOARD',
  'us.match.ncaaf.playByPlay': 'NCAAF_PLAY_BY_PLAY',
};

const {
  NCAAF_RANKS,
  NCAAF_STANDINGS_SLIM,
  NCAAF_SCOREBOARD,
  NCAAF_RECENT_GAMES,
  NCAAF_STATS,
  NCAAF_BOXSCORE,
  NCAAF_PLAY_BY_PLAY,
  NCAAF_PLAYER_COMPARISON,
  WNBA_STANDINGS_SLIM,
  WNBA_RECENT_GAMES,
  SOCCER_SCORING_PROBABILITY,
  SOCCER_STATS,
  SOCCER_RANKS,
  SOCCER_HEAD_TO_HEAD,
  SOCCER_LINEUPS,
  SOCCER_TIMELINE,
  SOCCER_MOMENTUM,
  SOCCER_SCOREBOARD,
  SOCCER_PLAYER_COMPARISON,
  NCAAB_SCOREBOARD,
  MLB_SCOREBOARD,
  NBA_SCOREBOARD,
  NBA_COMPARISON,
  NBA_STATISTICS,
  NBA_TEAM_STATS,
  NBA_STANDINGS_SLIM,
  LEADERS,
  NBA_BOXSCORE,
  NBA_PLAY_BY_PLAY,
  WIN_PROBABILITY,
  NBA_RECENT_GAMES,
  INJURIES,
  MLB_STATS,
  MLB_PLAY_BY_PLAY,
  LIVE_MATCH_TRACKER,
  ODDS,
  BETTING_STATS,
  MLB_STANDINGS_SLIM,
  MLB_BOXSCORE,
  MLB_GAME_TRACKER,
  MLB_LINEUPS,
  MLB_RECENT_GAMES,
  PITCHER_COMPARISON,
  NHL_SCOREBOARD,
  NHL_PLAY_BY_PLAY,
  NHL_GAME_TRACKER,
  NHL_RANKS,
  NHL_RECENT_GAMES,
  NHL_TEAM_STATS,
  NHL_STANDINGS_SLIM,
  NHL_BOXSCORE,
  NFL_SCOREBOARD,
  NFL_BOXSCORE,
  NFL_PLAY_BY_PLAY,
  COACHS_CORNER,
  NFL_STANDINGS_SLIM,
  NFL_GAME_SUMMARY,
  NFL_RECENT_GAMES,
  NFL_PROBABILITY,
  NFL_INJURIES,
  NFL_WIN_PROBABILITY,
  NFL_BETTING_STATS,
  NCAAB_STANDINGS_SLIM,
  NCAAB_RECENT_GAMES,
  NCAAB_TEAM_STATS,
  NCAAB_BOXSCORE,
  NCAAB_PLAY_BY_PLAY,
  NCAA_LEADERS,
  NCAA_ODDS,
  NCAAB_STATS,
  NCAAB_RANKS,
} = SPORTRADAR_NAMES;

export const SPORTRADAR_PROPS_MAPPING: Record<string, any> = {
  GOLF_LEADERBOARD: {
    disableSeasonDropdown: true,
    pageLength: 8,
    columns: 'pos, score, thru',
  },
  TEAM_HEADER: {
    teamUid: undefined,
    seasonId: undefined,
  },
  TEAM_MATCH_SMALL: {
    matchId: undefined,
  },
  TICKER: {
    nextDays: 2,
    layout: 'compact',
    oddsType: 'spread',
    showOdds: true,
    dayFlipHoursBefore: 3,
    linkGame: true,
    dayFlipUTCHours: 15,
    allowedSports: 'nba,mlb,nfl,soccer,nhl,wnba,ncaaf,ncaab',
    prevDays: 1,
    sport: undefined,
    onItemClick: undefined,
  },
  ODDS: {
    title: 'Game Odds',
    matchId: undefined,
    disableHeader: true,
  },
  BETTING_STATS: {
    title: 'Betting Stats',
    matchId: undefined,
  },
  LIVE_MATCH_TRACKER: {
    matchId: undefined,
    enableTeamColors: true,
  },
  LEADERS: {
    title: 'Team Leaders',
    matchId: undefined,
    enableTeamColors: true,
  },
  NBA_TEAM_LEADERS: {
    title: 'Team Leaders',
    uniqueTeamId: undefined,
    disableHeaderDetails: true,
    disableHeader: true,
    seasonId: undefined,
  },
  NBA_PLAY_BY_PLAY: {
    enableTeamColors: true,
    sortOrder: 'descending',
    matchId: undefined,
  },
  NBA_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    disableTeamDropdown: true,
    disableHeader: true,
    nextGames: 3,
  },
  NBA_RANKS: {
    title: 'Team Rankings',
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
  },
  NBA_BOXSCORE: {
    enableTeamColors: true,
    matchId: undefined,
    enableSideBySideDisplay: true,
  },
  NBA_LINESCORE: {
    matchId: undefined,
    transformTeamId: true,
  },
  NBA_SCOREBOARD: {
    title: undefined,
    matchId: undefined,
    linkTeam: true,
  },
  WIN_PROBABILITY: {
    title: 'Win Probability',
    matchId: undefined,
    enableTeamColors: true,
  },
  NBA_STATISTICS: {
    title: 'Game Stats',
    matchId: undefined,
    disableMatchHeader: true,
    disableScoreboardTable: true,
    enableTeamColors: true,
    disableWidgetHeader: true,
  },
  NBA_TEAM_STATS: {
    title: 'Team Stats',
    enableTeamColors: true,
    matchId: undefined,
    disableHeader: true,
  },
  NBA_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    uniqueTeamId: undefined,
    enableTeamColors: true,
    disableHeader: true,
    seasonId: undefined,
  },
  NBA_COMPARISON: {
    title: 'Team Comparison',
    matchId: undefined,
    enableTeamColors: true,
    disableWidgetHeader: true,
    disableTeamDropdown: true,
  },
  WNBA_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    seasonId: undefined,
    teamId: undefined,
    enableTeamColors: true,
    disableHeader: true,
  },
  WNBA_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    nextGames: 3,
    disableTeamDropdown: true,
    disableHeader: true,
  },
  INJURIES: {
    teamTitle: true,
    title: 'Injuries',
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
    disableTeamHeaders: true,
  },
  MLB_SCOREBOARD: {
    title: undefined,
    matchId: undefined,
    linkTeam: true,
  },
  MLB_LINEUPS: {
    title: 'Lineups',
    matchId: undefined,
    enableTeamColors: true,
  },
  MLB_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    nextGames: 3,
    disableTeamDropdown: true,
    disableHeader: true,
  },
  MLB_STATS: {
    title: 'Betting Stats',
    matchId: undefined,
    enableTeamColors: true,
  },
  MLB_RANKS: {
    title: 'Team Rankings',
    uniqueTeamId: undefined,
    category: undefined,
    seasonId: undefined,
    disableHeader: true,
  },
  MLB_TEAM_LEADERS: {
    title: 'Team Leaders',
    seasonId: undefined,
    uniqueTeamId: undefined,
    disableHeader: true,
    hideTitle: true,
    qualifiesAsLeagueLeader: true,
  },
  MLB_PLAY_BY_PLAY: {
    sortOrder: 'descending',
    enableTeamColors: true,
    matchId: undefined,
    disableInnerScrolling: true,
  },
  PITCHER_COMPARISON: {
    title: 'Pitcher Comparison',
    matchId: undefined,
    enableTeamColors: true,
  },
  MLB_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    uniqueTeamId: undefined,
    seasonId: undefined,
    enableTeamColors: true,
    disableHeader: true,
    transformTeamId: true,
  },
  MLB_BOXSCORE: {
    enableTeamColors: true,
    matchId: undefined,
    disableWidgetHeader: true,
    homeStats: 'players',
    enableTeamTabs: true,
    enableSideBySideDisplay: true,
    awayStats: 'players',
  },
  MLB_GAME_TRACKER: {
    title: 'Game Tracker',
    matchId: undefined,
    enableTeamColors: true,
  },
  NHL_SCOREBOARD: {
    title: undefined,
    matchId: undefined,
    linkTeam: true,
  },
  NHL_PLAY_BY_PLAY: {
    enableTeamColors: true,
    matchId: undefined,
    sortOrder: 'descending',
    disableInnerScrolling: true,
  },
  NHL_GAME_TRACKER: {
    title: 'Game Tracker',
    matchId: undefined,
    enableTeamColors: true,
  },
  NHL_RANKS: {
    teamTitle: true,
    title: 'Team Rankings',
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
    enableTeamColors: true,
  },
  NHL_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    nextGames: 3,
    disableTeamDropdown: true,
    disableHeader: true,
  },
  NHL_TEAM_LEADERS: {
    title: 'Team Leaders',
    uniqueTeamId: undefined,
    seasonId: undefined,
    disableHeader: true,
    hideTitle: true,
    qualifiesAsLeagueLeader: true,
  },
  NHL_TEAM_STATS: {
    title: 'Team Stats',
    enableTeamColors: true,
    matchId: undefined,
    disableHeader: true,
  },
  NHL_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    uniqueTeamId: undefined,
    enableTeamColors: true,
    disableHeader: true,
    transformTeamId: true,
    seasonId: undefined,
  },
  NHL_BOXSCORE: {
    enableTeamColors: true,
    matchId: undefined,
    disableWidgetHeader: true,
    enableTeamTabs: true,
    enableSideBySideDisplay: true,
  },
  NFL_LEADERS: {
    title: 'Team Leaders',
    matchId: undefined,
    enableTeamColors: true,
  },
  COACHS_CORNER: {
    title: "Coach's Corner",
    enableTeamSelect: true,
    disableWidgetHeader: true,
    matchId: undefined,
    disableMatchHeader: true,
    useTeamColors: true,
  },
  NFL_BETTING_STATS: {
    title: 'Betting Stats',
    matchId: undefined,
    enableTeamColors: true,
  },
  NFL_WIN_PROBABILITY: {
    title: 'Win Probability',
    matchId: undefined,
    enableTeamColors: true,
  },
  NFL_PLAY_BY_PLAY: {
    useTeamColors: true,
    disableInnerScrolling: true,
    sortOrder: 'descending',
    matchId: undefined,
  },
  NFL_BOXSCORE: {
    enableTeamColors: true,
    matchId: undefined,
    disableWidgetHeader: true,
    enableTeamTabs: true,
    enableSideBySideDisplay: true,
  },
  NFL_SCOREBOARD: {
    matchId: undefined,
    linkTeam: true,
  },
  NFL_GAME_SUMMARY: {
    title: 'Game Summary',
    matchId: undefined,
    enableTeamColors: true,
    enableTeamSelect: true,
    disableWidgetHeader: true,
    teamColorsTheme: 'mono-team-colors-light',
    disableMatchHeader: true,
    useTeamColors: true,
    league: 'nfl',
    seasonId: 2023,
  },
  NFL_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    uniqueTeamId: undefined,
    enableTeamColors: true,
    disableHeader: true,
    transformTeamId: true,
    seasonId: undefined,
  },
  NFL_TEAM_HEADER: {
    teamId: undefined,
    seasonId: undefined,
  },
  NFL_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamId: undefined,
    currentSeason: true,
    pastGames: 17,
    nextGames: 17,
    sortDirection: 'asc',
    disableDropdown: true,
    disableHeaderDetails: true,
  },
  NFL_INJURIES: {
    teamTitle: true,
    title: 'Injuries',
    teamId: undefined,
    seasonId: undefined,
    disableHeader: true,
  },
  NFL_PROBABILITY: {
    title: 'Game Probabilities',
    matchId: undefined,
    enableTeamColors: true,
    disableWidgetHeader: true,
    disableMatchHeader: true,
  },
  NFL_TEAM_LEADERS: {
    title: 'Team Leaders',
    teamId: undefined,
    seasonId: undefined,
    hideTitle: true,
    disableHeaderDetails: true,
    disableHeader: true,
    qualifiesAsLeagueLeader: true,
  },
  NCAAF_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    uniqueTeamId: undefined,
    enableTeamColors: true,
    disableHeader: true,
    transformTeamId: true,
    seasonId: undefined,
  },
  NCAAF_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    nextGames: 3,
    disableDropdown: true,
    disableHeaderDetails: true,
  },
  NCAAF_TEAM_LEADERS: {
    title: 'Team Leaders',
    uniqueTeamId: undefined,
    seasonId: undefined,
    hideTitle: true,
    disableHeaderDetails: true,
    disableHeader: true,
    qualifiesAsLeagueLeader: true,
  },
  NCAAF_RANKS: {
    teamTitle: true,
    title: 'Team Rankings',
    rankType: 'conference',
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
  },
  NCAAF_SCHEDULE: {
    title: 'Schedule',
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
    disableTeamDropdown: true,
  },
  NCAAB_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    nextGames: 3,
    disableHeader: true,
    disableTeamDropdown: true,
  },
  NCAAB_STANDINGS_SLIM: {
    teamTitle: true,
    title: 'Standings',
    uniqueTeamId: undefined,
    enableTeamColors: true,
    disableHeader: true,
    transformTeamId: true,
    seasonId: undefined,
  },
  NCAAB_TEAM_LEADERS: {
    title: 'Team Leaders',
    uniqueTeamId: undefined,
    seasonId: undefined,
    hideTitle: true,
    disableHeaderDetails: true,
    disableHeader: true,
    qualifiesAsLeagueLeader: true,
  },
  NCAAB_RANKS: {
    teamTitle: true,
    title: 'Team Rankings',
    rankType: 'conference',
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
  },
  NCAAB_SCOREBOARD: {
    matchId: undefined,
    linkTeam: true,
  },
  NCAAB_TEAM_STATS: {
    title: 'Team Stats',
    enableTeamColors: true,
    matchId: undefined,
    disableHeader: true,
  },
  NCAAB_BOXSCORE: {
    homeStats: 'players',
    awayStats: 'players',
    enableTeamColors: true,
    matchId: undefined,
    disableWidgetHeader: true,
    enableTeamTabs: true,
    enableSideBySideDisplay: true,
  },
  NCAAB_PLAY_BY_PLAY: {
    sortOrder: 'descending',
    enableTeamColors: true,
    matchId: undefined,
    disableInnerScrolling: true,
  },
  NCAA_LEADERS: {
    title: 'Team Leaders',
    matchId: undefined,
    enableTeamColors: true,
    disableHeader: true,
  },
  NCAA_ODDS: {
    title: 'Game Odds',
    matchId: undefined,
    disableHeader: true,
  },
  NCAAB_STATS: {
    title: 'Betting Stats',
    matchId: undefined,
  },
  SOCCER_SCOREBOARD: {
    showOdds: false,
    enableTeamColors: true,
    matchId: undefined,
  },
  SOCCER_MOMENTUM: {
    enableTeamColors: true,
    matchId: undefined,
    disableWidgetHeader: true,
  },
  SOCCER_TIMELINE: { enableTeamColors: true, matchId: undefined, disableWidgetHeader: true },
  SOCCER_LINEUPS: { enableTeamColors: true, matchId: undefined, disableWidgetHeader: true, disableMatchHeader: true },
  SOCCER_RECENT_GAMES: {
    teamTitle: true,
    title: 'Schedule',
    teamUid: undefined,
    currentSeason: true,
    pastGames: 10,
    nextGames: 3,
    disableHeader: true,
    seasonId: undefined,
  },
  SOCCER_COMPARISON: {
    title: 'Team Comparison',
    matchId: undefined,
    enableTeamColors: true,
    disableWidgetHeader: true,
    disableTeamSelector: true,
  },
  SOCCER_RANKS: {
    title: 'Team Rankings',
    teamTitle: true,
    teamUid: undefined,
    seasonId: undefined,
    disableHeader: true,
    enableTeamColors: true,
  },
  SOCCER_STATS: {
    title: 'Game Stats',
    matchId: undefined,
    disableMatchHeader: true,
    disableWidgetHeader: true,
  },
  SOCCER_SCORING_PROBABILITY: {
    title: 'Scoring Probability',
    matchId: undefined,
    enableTeamColors: true,
    disableWidgetHeader: true,
    disableMatchHeader: true,
    disableTitle: true,
  },
  SOCCER_HEAD_TO_HEAD: {
    title: 'Head to Head',
    matchId: undefined,
    enableTeamColors: true,
    disableWidgetHeader: true,
  },
  SOCCER_PLAYER_COMPARISON: {
    title: 'Player Comparison',
    matchId: undefined,
    enableTeamColors: true,
    disableScore: true,
  },
  NCAAF_SCOREBOARD: {
    matchId: undefined,
    linkTeam: true,
  },
  NCAAF_STATS: {
    title: 'Betting Stats',
    matchId: undefined,
  },
  NCAAF_BOXSCORE: {
    homeStats: 'players',
    awayStats: 'players',
    enableTeamColors: true,
    matchId: undefined,
    disableWidgetHeader: true,
    enableTeamTabs: true,
    enableSideBySideDisplay: true,
  },
  NCAAF_PLAY_BY_PLAY: {
    sortOrder: 'descending',
    enableTeamColors: true,
    matchId: undefined,
    disableInnerScrolling: true,
  },
  NCAAF_PLAYER_COMPARISON: {
    title: 'Player Comparison',
    enableTeamColors: true,
    matchId: undefined,
  },
};

export const STANDARD_PROPS = {
  dataId: undefined,
  widgetId: undefined,
  border: false,
  language: 'en_us',
};

export const TEAM_ID_KEYS = ['uniqueTeamId', 'teamUid', 'teamId'];

export const WIDGETS_WITH_SR_MATCH_ID = [
  SPORTRADAR_NAMES.ODDS,
  SPORTRADAR_NAMES.LIVE_MATCH_TRACKER,
];

export const SPORTRADAR_MATCH_MAPPING: Record<string, any> = {
  nba: {
    pre: {
      game: [
        NBA_SCOREBOARD,
        NBA_COMPARISON,
        INJURIES,
        INJURIES,
        WIN_PROBABILITY,
        NBA_RECENT_GAMES,
        NBA_RECENT_GAMES,
      ],
      betting: [NBA_SCOREBOARD, BETTING_STATS, ODDS, WIN_PROBABILITY],
    },
    live: {
      game: [NBA_SCOREBOARD, LIVE_MATCH_TRACKER],
      boxScore: [NBA_SCOREBOARD, NBA_BOXSCORE],
      playByPlay: [NBA_SCOREBOARD, NBA_PLAY_BY_PLAY],
    },
    post: {
      game: [
        NBA_SCOREBOARD,
        NBA_STATISTICS,
        NBA_TEAM_STATS,
        LEADERS,
        NBA_STANDINGS_SLIM,
        NBA_STANDINGS_SLIM,
      ],
      boxScore: [NBA_SCOREBOARD, NBA_BOXSCORE],
      playByPlay: [NBA_SCOREBOARD, NBA_PLAY_BY_PLAY],
    },
  },
  mlb: {
    pre: {
      game: [
        MLB_SCOREBOARD,
        MLB_LINEUPS,
        PITCHER_COMPARISON,
        INJURIES,
        INJURIES,
        WIN_PROBABILITY,
        MLB_RECENT_GAMES,
        MLB_RECENT_GAMES,
      ],
      betting: [MLB_SCOREBOARD, MLB_STATS, ODDS, WIN_PROBABILITY],
    },
    live: {
      game: [MLB_SCOREBOARD, MLB_GAME_TRACKER, LIVE_MATCH_TRACKER],
      boxScore: [MLB_SCOREBOARD, MLB_BOXSCORE],
      playByPlay: [MLB_SCOREBOARD, MLB_PLAY_BY_PLAY],
    },
    post: {
      game: [
        MLB_SCOREBOARD,
        MLB_PLAY_BY_PLAY,
        MLB_STANDINGS_SLIM,
        MLB_STANDINGS_SLIM,
      ],
      boxScore: [MLB_SCOREBOARD, MLB_BOXSCORE],
      playByPlay: [MLB_SCOREBOARD, MLB_PLAY_BY_PLAY],
    },
  },
  nhl: {
    pre: {
      game: [
        NHL_SCOREBOARD,
        LEADERS,
        NHL_RANKS,
        NHL_RANKS,
        INJURIES,
        INJURIES,
        WIN_PROBABILITY,
        NHL_RECENT_GAMES,
        NHL_RECENT_GAMES,
      ],
      betting: [NHL_SCOREBOARD, BETTING_STATS, ODDS, WIN_PROBABILITY],
    },
    live: {
      game: [NHL_SCOREBOARD, NHL_GAME_TRACKER, LIVE_MATCH_TRACKER],
      boxScore: [NHL_SCOREBOARD, NHL_BOXSCORE],
      playByPlay: [NHL_SCOREBOARD, NHL_PLAY_BY_PLAY],
    },
    post: {
      game: [
        NHL_SCOREBOARD,
        NHL_TEAM_STATS,
        LEADERS,
        NHL_RANKS,
        NHL_RANKS,
        NHL_STANDINGS_SLIM,
        NHL_STANDINGS_SLIM,
      ],
      boxScore: [NHL_SCOREBOARD, NHL_BOXSCORE],
      playByPlay: [NHL_SCOREBOARD, NHL_PLAY_BY_PLAY],
    },
  },
  nfl: {
    pre: {
      game: [
        NFL_SCOREBOARD,
        NFL_PROBABILITY,
        NFL_INJURIES,
        NFL_INJURIES,
        NFL_WIN_PROBABILITY,
        NFL_RECENT_GAMES,
        NFL_RECENT_GAMES,
      ],
      betting: [NFL_SCOREBOARD, NFL_BETTING_STATS, ODDS, NFL_WIN_PROBABILITY],
    },
    live: {
      game: [NFL_SCOREBOARD, LIVE_MATCH_TRACKER],
      boxScore: [NFL_SCOREBOARD, NFL_BOXSCORE],
      playByPlay: [NFL_SCOREBOARD, NFL_PLAY_BY_PLAY],
    },
    post: {
      game: [
        NFL_SCOREBOARD,
        NFL_GAME_SUMMARY,
        COACHS_CORNER,
        NFL_STANDINGS_SLIM,
        NFL_STANDINGS_SLIM,
      ],
      boxScore: [NFL_SCOREBOARD, NFL_BOXSCORE],
      playByPlay: [NFL_SCOREBOARD, NFL_PLAY_BY_PLAY],
    },
  },
  ncaab: {
    pre: {
      game: [
        NCAAB_SCOREBOARD,
        NCAAB_RANKS,
        NCAAB_RANKS,
        WIN_PROBABILITY,
        NCAAB_RECENT_GAMES,
        NCAAB_RECENT_GAMES,
      ],
      betting: [NCAAB_SCOREBOARD, NCAAB_STATS, WIN_PROBABILITY, NCAA_ODDS],
    },
    live: {
      game: [NCAAB_SCOREBOARD, LIVE_MATCH_TRACKER],
      boxScore: [NCAAB_SCOREBOARD, NCAAB_BOXSCORE],
      playByPlay: [NCAAB_SCOREBOARD, NCAAB_PLAY_BY_PLAY],
    },
    post: {
      game: [
        NCAAB_SCOREBOARD,
        NCAAB_TEAM_STATS,
        NCAA_LEADERS,
        NCAAB_STANDINGS_SLIM,
        NCAAB_STANDINGS_SLIM,
      ],
      boxScore: [NCAAB_SCOREBOARD, NCAAB_BOXSCORE],
      playByPlay: [NCAAB_SCOREBOARD, NCAAB_PLAY_BY_PLAY],
    },
  },
  ncaaf: {
    pre: {
      game: [
        NCAAF_SCOREBOARD,
        NCAAF_RANKS,
        NCAAF_RANKS,
        WIN_PROBABILITY,
        NCAAF_RECENT_GAMES,
        NCAAF_RECENT_GAMES,
      ],
      betting: [NCAAF_SCOREBOARD, NCAAF_STATS, WIN_PROBABILITY, NCAA_ODDS],
    },
    live: {
      game: [NCAAF_SCOREBOARD, LIVE_MATCH_TRACKER],
      boxScore: [NCAAF_SCOREBOARD, NCAAF_BOXSCORE],
      playByPlay: [NCAAF_SCOREBOARD, NCAAF_PLAY_BY_PLAY],
    },
    post: {
      game: [
        NCAAF_SCOREBOARD,
        NCAAF_PLAYER_COMPARISON,
        NCAA_LEADERS,
        NCAAF_STANDINGS_SLIM,
        NCAAF_STANDINGS_SLIM,
      ],
      boxScore: [NCAAF_SCOREBOARD, NCAAF_BOXSCORE],
      playByPlay: [NCAAF_SCOREBOARD, NCAAF_PLAY_BY_PLAY],
    },
  },
  soccer: {
    pre: {
      game: [SOCCER_SCOREBOARD, SOCCER_HEAD_TO_HEAD, SOCCER_RANKS, SOCCER_RANKS],
      betting: [SOCCER_SCOREBOARD, SOCCER_PLAYER_COMPARISON, ODDS, SOCCER_SCORING_PROBABILITY],
    },
    live: {
      game: [SOCCER_SCOREBOARD, SOCCER_MOMENTUM, SOCCER_STATS],
      playByPlay: [SOCCER_SCOREBOARD, SOCCER_TIMELINE],
      boxScore: [SOCCER_SCOREBOARD],
    },
    post: {
      game: [SOCCER_SCOREBOARD, SOCCER_LINEUPS],
      playByPlay: [SOCCER_SCOREBOARD, SOCCER_TIMELINE],
      boxScore: [SOCCER_SCOREBOARD],
    },
  },
  wnba: {
    pre: {
      game: [
        NBA_SCOREBOARD,
        NBA_COMPARISON,
        INJURIES,
        INJURIES,
        WIN_PROBABILITY,
        WNBA_RECENT_GAMES,
        WNBA_RECENT_GAMES,
      ],
      betting: [NBA_SCOREBOARD, BETTING_STATS, ODDS, WIN_PROBABILITY],
    },
    live: {
      game: [NBA_SCOREBOARD, LIVE_MATCH_TRACKER],
      boxScore: [NBA_SCOREBOARD, NBA_BOXSCORE],
      playByPlay: [NBA_SCOREBOARD, NBA_STATISTICS],
    },
    post: {
      game: [
        NBA_SCOREBOARD,
        NBA_STATISTICS,
        LEADERS,
        WNBA_STANDINGS_SLIM,
        WNBA_STANDINGS_SLIM,
      ],
      boxScore: [NBA_SCOREBOARD, NBA_BOXSCORE],
      playByPlay: [NBA_SCOREBOARD, NBA_TEAM_STATS],
    },
  },
};
