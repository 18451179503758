import React from 'react';
import { Kasda } from '../../Kasda/Kasda';
import { getPublisherNameAndLogo } from './SISchemaUtils';

type AuthorsList = {
  name: string;
  link?: string;
  description?: string;
  email?: string;
};

interface LiveBlogSchemaDataProps {
  pageUrl: string;
  title: string;
  image: string;
  datePublished: string;
  authors: AuthorsList[] | null;
  publisherName: string;
  logoUrl: string;
  dateModified: string;
  seoDescription: string;
  liveBlogData: Record<string, string>[];
}

export const LiveBlogSchema = (props: LiveBlogSchemaDataProps) => {
  const {
    title,
    datePublished,
    authors,
    publisherName,
    logoUrl,
    dateModified,
    seoDescription,
    pageUrl,
  } = props;

  const dateToIsoDate = (date: string) => {
    return new Date(date).toISOString();
  };

  const getAuthorsListSchema = () => {
    return authors?.length
      ? authors.map((author: AuthorsList) => ({
        '@context': 'http://schema.org',
        '@type': 'Person',
        name: author.name,
        ...(author.description ? { description: author.description } : {}),
        ...(author.email ? { email: author.email } : {}),
        ...(author.link ? { url: author.link } : {}),
      }))
      : [];
  };

  const {
    publisherName: calculatedPublisherName,
    logoUrl: calculatedLogoUrl,
  } = getPublisherNameAndLogo(publisherName, logoUrl, pageUrl);

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'LiveBlogPosting',
    headline: title,
    url: pageUrl,
    datePublished: dateToIsoDate(datePublished),
    dateModified: dateToIsoDate(dateModified),
    coverageStartTime: '',
    coverageEndTime: '',
    description: seoDescription,
    author: getAuthorsListSchema(),
    publisher: {
      '@type': 'Organization',
      name: calculatedPublisherName,
      logo: {
        '@type': 'ImageObject',
        url: calculatedLogoUrl,
      },
    },
  };

  return (
    <Kasda.Script>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Kasda.Script>
  );
};
