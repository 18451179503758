const OLD_SECTION_NAMES = {
  CARDS_SECTION: 'cardsSection' as const,
  CONTENT_SECTION_1: 'contentSection1' as const,
  CONTENT_SECTION_2: 'contentSection2' as const,
  CONTENT_SECTION_3: 'contentSection3' as const,
  CONTENT_SECTION_4: 'contentSection4' as const,
  CONTENT_SECTION_5: 'contentSection5' as const,
  CONTENT_SECTION_6: 'contentSection6' as const,
  CONTENT_SECTION_7: 'contentSection7' as const,
};

export const NUMBERED_SECTIONS = {
  SECTION1: 'section1' as const,
  SECTION2: 'section2' as const,
  SECTION3: 'section3' as const,
  SECTION4: 'section4' as const,
  SECTION5: 'section5' as const,
  SECTION6: 'section6' as const,
  SECTION7: 'section7' as const,
  SECTION8: 'section8' as const,
  SECTION9: 'section9' as const,
  SECTION10: 'section10' as const,
  SECTION11: 'section11' as const,
  SECTION12: 'section12' as const,
};

export const SECTION_NAMES = {
  ...OLD_SECTION_NAMES,
  ...NUMBERED_SECTIONS,
  TOP_SECTION: 'topSection' as const,
  FEED_SECTION: 'feedSection' as const,
  FEED_SECTION1: 'feedSection1' as const,
  FEED_SECTION2: 'feedSection2' as const,
  EXPANDABLE_SECTION: 'expandableSection' as const,
  AUTHOR_SECTION: 'articles' as const,
  SPONSOR_SECTION: 'sponsoredSection' as const,
  COMMERCIAL_SECTION: 'commercialSection' as const,
};

export const SLOT_NAMES = {
  TOP_AD: 'top',
  ONE_ON_ONE: 'oneOnOne',
  IN_ARTICLE: 'inArticle',
  SIDE_BAR_1: 'sidebar1',
  SIDE_BAR_2: 'sidebar2',
  STICKY_BOTTOM: 'stickyBottom',
  BELOW_TOP_SECTION: 'belowTopSection',
  BELOW_SECOND_SECTION: 'belowSecondSection',
};

export enum SPORTS {
  SOCCER = 'soccer',
  NHL = 'nhl',
  NBA = 'nba',
  NFL = 'nfl',
  NCAAF = 'ncaaf',
  NCAAB = 'ncaab',
  MLB = 'mlb',
  WNBA = 'wnba',
  GAME = 'game',
  HOME = 'Home',
  AWAY = 'Away',
  NCAA = 'ncaa',
  BETTING = 'betting',
}

export const TICKER_LEAGUES: {[key: number]: string} = {
  1: SPORTS.SOCCER,
  2: SPORTS.NBA,
  3: SPORTS.MLB,
  4: SPORTS.NHL,
  5: SPORTS.NFL,
  6: SPORTS.NCAAF,
  7: SPORTS.NCAAB,
};
