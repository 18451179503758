import * as React from 'react';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import {
  AdBlock,
  ApesterEmbedBlock,
  Block,
  BlocksPaddingOverride,
  BlockWidths,
  CerosBlock,
  ContentRecommendationsBlockProps,
  FeedsRecommendationsBlockProps,
  GenericBlock,
  GiphyBlock,
  HTMLBlock,
  IframeEmbedBlock,
  ImageBlock,
  ImportedEmbeddedContentBlock,
  InjectedHtmlBlock,
  JwBlock,
  ListItemTitleBlock,
  MMPlayerBlock,
  OverrideBlocksWidths,
  QuoteBlock,
  TableOfContentsBlock,
  TableBlock,
  FAQBlock,
  SportradarBlock,
} from './BlockTypes.utils';
import { Paragraph } from '../../articleComponents/Paragraph';
import { Playbuzz } from '../../articleComponents/media/Playbuzz';
import { Streamable } from '../../articleComponents/media/Streamable';
import { Twitter } from '../../articleComponents/media/Twitter';
import { Giphy } from '../../articleComponents/media/Giphy';
import { Instagram } from '../../articleComponents/media/Instagram';
import { Facebook } from '../../articleComponents/media/Facebook';
import { ListItemTitle } from '../../articleComponents/ListItemTitle';
import { useViewabilityHandler, ViewabilityProvider } from '../../../viewability/viewabilityHandler';
import { InjectedBlock } from '../../articleComponents/media/InjectedBlock';
import { IframeEmbed } from '../../articleComponents/media/IframeEmbed';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { Gfycat } from '../../articleComponents/media/Gfycat';
import { Apester } from '../../articleComponents/media/Apester';
import { Ceros } from '../../articleComponents/media/Ceros';
import { ArticleAd } from '../../articleComponents/ArticleAd';
import { ArticleIframe } from '../../articleComponents/media/ArticleIframe';
import { ArticleJw } from '../../articleComponents/media/ArticleJw';
import { ArticleMMPlayer } from '../../articleComponents/media/ArticleMMPlayer';
import { ContentEnrichment } from '../../articleComponents/media/ContentEnrichment';
import { SlideshowContext } from '../../articleComponents/slideshow/Slideshow';
import { ArticleImage } from '../../articleComponents/ArticleImage';
import { Quote } from '../../articleComponents/Quote';
import { ArticleDividerWithIcon } from '../../articleComponents/divider/ArticleDividerWithIcon';
import { QuoteIconForDivider } from '../../articleComponents/divider/QuoteIconForDivider';
import { createBlockStyle, createBlockStyleSheet, idGenerator } from './utils';
import { ConditionalImageLinkWrapper } from '../../partials/image/ConditionalImageLinkWrapper';
import { MMPlayerPlaceHolder } from '../../partials/MMPlayerPlaceHolder';
import { OpenwebLiveblog } from '../../partials/OpenwebLiveblog';
import { ImageWidth } from '../../partials/image/image.utils';
import { RelatedTopics } from '../../articleComponents/relatedTopics/RelatedTopics';
import { ImportedTable } from '../../articleComponents/ImportedTable';
import { TableOfContents } from '../../articleComponents/tableOfContents/TableOfContents';
import { Table } from '../../articleComponents/table/Table';
import { ImportedEmbeddedContent } from '../../articleComponents/importedEmbeddedContent/ImportedEmbeddedContent';
import { FAQ } from '../../articleComponents/FAQ';
import { SuggestedPosts } from '../../articleComponents/suggestedPosts/SuggestedPosts';
import { LiveBlog } from '../../articleComponents/media/LiveBlog';
import { RawHtmlContent } from '../../articleComponents/RawHtmlContent';
import { Sportradar } from '../sportradar/Sportradar';

export const componentConfigurationKey = 'articleContent';

export interface ArticleContentBaseProps {
  body: Array<Block> | null;
}

interface ArticleContentDataProps extends ArticleContentBaseProps {
  templateName: string;
  siteName: string;
  defaultBlocksWidths: BlockWidths;
  overrideBlocksWidths?: OverrideBlocksWidths;
  blocksPaddingOverride?: BlocksPaddingOverride;
  imageWidthSmall?: ImageWidth;
  imageWidthMedium?: ImageWidth;
  imageWidthLarge?: ImageWidth;
  addMMPlayerPlaceholder?: boolean;
  addOpenwebLiveblogPlaceholder?: boolean;
  totalSlides: number;
  experiments?: Array<string>;
  commercialTags: Array<string> ;
  slidesLinks?: Record<string, string>;
  resourceID?: string | null;
  ownerUsername: string;
  mmVideoPlaceholderId?: string;
  isMobileViewer: boolean;
}

export interface ArticleContentThemeProps {
  baseUnit: BaseUnit;
  marginBetweenBlocksFactor: number;
  marginBetweenBlocksFactorAfterTitle: number;
}

const getSlideBlocks = (body: Array<Block>, currentSlideNumber: number) => {
  const pageBreakBlockIndexes = body.reduce((indexes: Array<number>, block, index) => {
    if (block.type === 'page-break') {
      return [...indexes, index];
    }
    return indexes;
  }, []);
  const indexOfFirstBlock = currentSlideNumber === 1 ? 0 : (pageBreakBlockIndexes[currentSlideNumber - 2] + 1);
  const indexOfLastBlock = pageBreakBlockIndexes[currentSlideNumber - 1];
  return body.slice(indexOfFirstBlock, indexOfLastBlock);
};

const createBlocksComponents = (body: Array<Block>, templateName: string, siteName: string, overrideBlockWidths: OverrideBlocksWidths | undefined, defaultBlocksWidths: BlockWidths, themeProps: ArticleContentThemeProps, blocksPaddingOverride: BlocksPaddingOverride | undefined, slideNumber: number | null, imageWidthLarge: ImageWidth | undefined, imageWidthMedium: ImageWidth | undefined, imageWidthSmall: ImageWidth | undefined, pageId?: string | null, experiment?: string) => {
  const { baseUnit, marginBetweenBlocksFactorAfterTitle, marginBetweenBlocksFactor } = themeProps;

  let isPreviousElementTitle = false;
  const bodyBlocksToRender = slideNumber !== null ? getSlideBlocks(body, slideNumber) : body;
  if (experiment?.includes('slideshow-test|test') && (bodyBlocksToRender[0].type !== 'page-break')) {
    bodyBlocksToRender.unshift({ type: 'page-break', dataId: idGenerator() });
  }
  let pageBreaksCounter = 0;
  return bodyBlocksToRender.map((block, index) => {
    const { type } = block;
    const regularBlockStyle = createBlockStyleSheet(baseUnit, marginBetweenBlocksFactor);
    const afterTitleBlockStyle = createBlockStyleSheet(baseUnit, marginBetweenBlocksFactorAfterTitle);
    const blockStyleSheet = isPreviousElementTitle ? afterTitleBlockStyle : regularBlockStyle;
    const blockStyle = isPreviousElementTitle ? createBlockStyle(baseUnit, marginBetweenBlocksFactorAfterTitle) : createBlockStyle(baseUnit, marginBetweenBlocksFactor);
    isPreviousElementTitle = false;
    switch (type) {
      case 'ad': {
        const { dataId, adId } = block as AdBlock;
        return (
          <ArticleAd
            key={dataId}
            additionalStyle={blockStyleSheet}
            id={adId}
            defaultBlocksWidths={defaultBlocksWidths}
            dataId={dataId}
          />
        );
      }
      case 'image': {
        const { image, dataId, sizeType } = block as ImageBlock;
        const overrideBlockWidth = overrideBlockWidths && overrideBlockWidths.image;
        if (image.rawHtml) {
          return (
            <RawHtmlContent
              key={dataId}
              html={image.rawHtml}
              dataId={dataId}
              defaultBlocksWidths={defaultBlocksWidths}
              additionalStyle={blockStyleSheet}
            />
          );
        }
        return (
          <ConditionalImageLinkWrapper link={image.link || null} key={`link-${dataId}`} dataId={dataId}>
            <ArticleImage
              key={dataId}
              image={image}
              style={blockStyle}
              dataId={dataId}
              defaultBlocksWidths={defaultBlocksWidths}
              overrideBlockWidths={overrideBlockWidth}
              sizeType={sizeType}
              blocksPaddingOverride={blocksPaddingOverride}
              imageWidthLarge={imageWidthLarge}
              imageWidthMedium={imageWidthMedium}
              imageWidthSmall={imageWidthSmall}
            />
          </ConditionalImageLinkWrapper>
        );
      }
      case 'imported-video': {
        const { dataId, html } = block as HTMLBlock;
        return (
          <RawHtmlContent
            key={dataId}
            html={html}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
            additionalStyle={blockStyleSheet}
          />
        );
      }
      case 'title': {
        isPreviousElementTitle = true;
        const { listItemNumber, text, dataId } = block as ListItemTitleBlock;
        return (
          <ListItemTitle
            key={dataId}
            listItemNumber={listItemNumber}
            text={text}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'inline-text': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Paragraph
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'quote': {
        const { text, cite, dataId } = block as QuoteBlock;
        return (
          <Quote
            key={dataId}
            text={text}
            cite={cite}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'live-blog': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <LiveBlog
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'youtube': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <ArticleIframe
            key={dataId}
            html={html}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'twitter': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Twitter
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'instagram': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Instagram
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'facebook': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Facebook
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'jw': {
        const { html, videoId, dataId } = block as JwBlock;
        return (
          <ArticleJw
            key={dataId}
            html={html}
            videoId={videoId}
            templateName={templateName}
            siteName={siteName}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'mmPlayer': {
        const { html, version, dataId } = block as MMPlayerBlock;
        return (
          <ArticleMMPlayer
            key={dataId}
            html={html}
            version={version}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'giphy': {
        const { dataId } = block as GenericBlock;
        return (
          <Giphy
            key={dataId}
            {...block as GiphyBlock}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'playbuzz': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Playbuzz
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'dailyMotion': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <ArticleIframe
            key={dataId}
            html={html}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'divider': {
        const { dataId } = block as GenericBlock;
        return (
          <ArticleDividerWithIcon
            key={dataId}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
            iconComponent={QuoteIconForDivider}
          />
        );
      }
      case 'streamable': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Streamable
            key={dataId}
            html={html}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'injectedBlock': {
        const { html, dataId, script } = block as InjectedHtmlBlock;
        return (
          <InjectedBlock
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            script={script}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'soundcloud': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <ArticleIframe
            key={dataId}
            html={html}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'vimeo': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <ArticleIframe
            key={dataId}
            html={html}
            style={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'iframeEmbed': {
        const { html, dataId, height } = block as IframeEmbedBlock;
        return (
          <IframeEmbed
            key={dataId}
            height={height}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'gfycat': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Gfycat
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'apester': {
        const { html, dataId } = block as ApesterEmbedBlock;
        return (
          <Apester
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'ceros': {
        const { html, dataId } = block as CerosBlock;
        return (
          <Ceros
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'table-of-contents': {
        const { dataId, title, headings } = block as TableOfContentsBlock;
        return (
          headings && (
          <TableOfContents
            headings={headings}
            title={title}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
            additionalStyle={blockStyleSheet}
            key={dataId}
          />
          )
        );
      }
      case 'relatedPosts': {
        const { articles, dataId, sourceType } = block as ContentRecommendationsBlockProps;
        const total_blocks = body ? body.filter(element => element.type !== 'injectedBlock').length : 0;
        return (
          <SuggestedPosts key={dataId} articles={articles} defaultBlocksWidths={defaultBlocksWidths} dataId={dataId} total_blocks={total_blocks} sourceType={sourceType} blockPosition={index} />
        );
      }
      case 'relatedTopics': {
        const { title, pages, dataId } = block as FeedsRecommendationsBlockProps;
        return (
          <RelatedTopics
            key={dataId}
            title={title}
            pages={pages}
            marginBetweenBlocksFactor={marginBetweenBlocksFactor}
            defaultBlocksWidths={defaultBlocksWidths}
            dataId={dataId}
          />
        );
      }
      case 'mm-content-embed': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <ContentEnrichment
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'imported-table': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <ImportedTable
            key={dataId}
            html={html}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'imported-embedded-content': {
        const { dataId } = block as ImportedEmbeddedContentBlock;
        return (
          <ImportedEmbeddedContent
            key={dataId}
            {...block as ImportedEmbeddedContentBlock}
            id={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
            additionalStyle={blockStyleSheet}
          />
        );
      }
      case 'table': {
        const { data, dataId } = block as TableBlock;
        return (
          <Table
            key={dataId}
            data={data}
            additionalStyle={blockStyleSheet}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'faq': {
        const { dataId, question, answer } = block as FAQBlock;
        return (
          <FAQ
            key={dataId}
            dataId={dataId}
            question={question}
            answer={answer}
            defaultBlocksWidths={defaultBlocksWidths}
            questionAdditionalStyle={regularBlockStyle}
            answerAdditionalStyle={createBlockStyleSheet(baseUnit, 0.25)}
          />
        );
      }
      case 'sportradar': {
        const { dataId, widgetId, language, teamUid, uniqueTeamId, playerId, seasonId, uniqueTournamentID, seasonType,
          pastGames, matchId, statType } = block as SportradarBlock;
        return (
          widgetId && (
            <Sportradar
              key={dataId}
              dataId={dataId}
              widgetId={widgetId}
              language={language}
              teamUid={teamUid}
              uniqueTeamId={uniqueTeamId}
              playerId={playerId}
              seasonId={seasonId}
              uniqueTournamentID={uniqueTournamentID}
              seasonType={seasonType}
              pastGames={pastGames}
              matchId={matchId}
              statType={statType}
              defaultBlocksWidths={defaultBlocksWidths}
            />
          )
        );
      }
      case 'page-break': {
        pageBreaksCounter += 1;
        return <div className={`page-break-${pageId}-${pageBreaksCounter}`} />;
      }
      default:
        return null;
    }
  });
};

export const ArticleContentComponent: React.FunctionComponent<ArticleContentDataProps> = props => {
  const {
    body,
    templateName,
    siteName,
    overrideBlocksWidths,
    defaultBlocksWidths,
    blocksPaddingOverride,
    imageWidthLarge,
    imageWidthMedium,
    imageWidthSmall,
    addMMPlayerPlaceholder,
    addOpenwebLiveblogPlaceholder,
    totalSlides,
    commercialTags,
    slidesLinks,
    experiments,
    resourceID,
    ownerUsername,
    mmVideoPlaceholderId,
    isMobileViewer,
  } = props;
  const themeProps = useTheme<ArticleContentThemeProps>(componentConfigurationKey);
  const { baseUnit, marginBetweenBlocksFactor } = themeProps;
  const slideNumber = React.useContext(SlideshowContext);

  const viewabilityCallback = React.useCallback(() => {
    if (slideNumber === null || slideNumber === totalSlides) {
      // @ts-ignore
      window.mmClientApi = window.mmClientApi || [];
      // @ts-ignore
      window.mmClientApi.push('analytics', {
        event_category: 'Article',
        event_action: 'article end',
        event_label: '',
      });
    }
  }, [slideNumber, totalSlides]);

  const ref = useViewabilityHandler(viewabilityCallback, 1);
  const pageId = resourceID;
  const option = { root: null, rootMargin: '0px 0px 0px 0px', threshold: 0 };
  const dataFetchedRef = React.useRef(false);
  React.useEffect(() => {
    const sendSlideShowEvent = (slideIndex: any, slideURL: string) => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: 'GTM data ready to GA - Slideshow',
        event_label: 'Slideshow page view',
        GA_event_action: 'Slideshow Pageview',
        slideNumber: slideIndex,
        isSlideshow: true,
        articleId: resourceID,
        commercialTags,
        experiments,
        ownerUsername,
        articleURL: slideURL || window.location.href.split('?')[0],
      });
    };

    const intersectionCallback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          try {
            if (window && slidesLinks) {
              const queryParams = window.location.search;
              const pageBreakNumber = entry.target.className.split('-')[3];
              const currentUrl = slidesLinks[pageBreakNumber] || slidesLinks[Object.keys(slidesLinks).length] || window.location.pathname;
              sendSlideShowEvent(`${pageBreakNumber}-${resourceID}`, slidesLinks[pageBreakNumber]);
              window.history.replaceState(null, 'URL change', `${currentUrl}${queryParams}`);
            }
          } catch (e) {
            console.log('slideshow error', e);
          }
        }
      });
    };
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    const observer = new IntersectionObserver(entries => intersectionCallback(entries), option);
    const elementsToObserve = document.querySelectorAll(`[class^="page-break-${pageId}"]`);

    elementsToObserve.forEach(element => {
      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    });
  }, [commercialTags, experiments, option, ownerUsername, pageId, resourceID, slidesLinks]);

  React.useEffect(() => {
    if (commercialTags?.includes('sponsored') && !document.getElementById('pressboard-sponsored-script')) {
      const scriptElement = document.createElement('script');
      scriptElement.id = 'pressboard-sponsored-script';
      scriptElement.innerHTML = "!(function (p, r, e, s, b, d) {p.pbq || (s = p.pbq = function () {s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);}, s.version = '1.0', s.queue = [], b = r.createElement(e), b.async = !0, b.src = 'https://sr.studiostack.com/v3/services',d = r.getElementsByTagName(e)[0], d.parentNode.insertBefore(b, d));}(window, document, 'script'));pbq('init', '250869');pbq('set', 'story');pbq('activate', 'ceros-universal-analytics');";
      document.head.appendChild(scriptElement);
    }
  }, [commercialTags]);

  return (
    <div ref={ref}>
      {
        addMMPlayerPlaceholder
          ? (
            <MMPlayerPlaceHolder
              renderForTabletAndDesktop
              additionalStyle={createBlockStyleSheet(baseUnit, marginBetweenBlocksFactor)}
              defaultBlocksWidths={defaultBlocksWidths}
              mmVideoPlaceholderId={mmVideoPlaceholderId}
              isMobileViewer={isMobileViewer}
            />
          ) : null
      }
      {
        body
          ? createBlocksComponents(
            body,
            templateName,
            siteName,
            overrideBlocksWidths,
            defaultBlocksWidths,
            themeProps,
            blocksPaddingOverride,
            slideNumber,
            imageWidthLarge,
            imageWidthMedium,
            imageWidthSmall,
            pageId,
            experiments?.join(),
          ) : null
      }
      {commercialTags?.includes('sponsored') && <div id="pressboard-ad-sponsorship-msg" />}
      {addOpenwebLiveblogPlaceholder
        ? (
          <OpenwebLiveblog
            additionalStyle={createBlockStyleSheet(baseUnit, marginBetweenBlocksFactor)}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        ) : null}
    </div>
  );
};

export const ArticleContent: React.FunctionComponent<ArticleContentDataProps> = props => {
  const { children } = props;

  return (
    <ErrorBoundary>
      <ViewabilityProvider>
        {children}
        <ArticleContentComponent {...props} />
      </ViewabilityProvider>
    </ErrorBoundary>
  );
};
