import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { Menu } from './menu/Menu';
import { InjectedVideoLogoApiProps, VideoLogo } from './VideoLogo';
import { Logo, LogoDataProps } from './Logo';
import { FixedNav } from './FixedNav';
import { SocialLinks } from './SocialLinks';
import { Editions } from './Editions';
import { SubMenu } from './SubMenu';
import { componentConfigThemeKey, HeaderThemeProps } from '../Header.theme';
import { AnimationDirection, AnimationDirectionType } from '../menuAnimations.util';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { getLineHeightBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../../theming/useTheme';
import { HeaderAnimationContext } from '../HeaderAnimation';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { SubMenuReducerProps, useSubMenu } from './subMenuReducer';
import { useLinksWithSubMenuHover } from './linkWithSubHeader.utils';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { WebFontsLoadingFlagContext } from '../../../../fonts/WebFonts';
import { SocialLink, SocialSectionDataProps } from '../../footer/AdditionalFooterSections/FooterSocialLinks';
import { EditionsIconDataProps } from './EditionsIcon';
import { MenuLinkDataProps } from '../oldHeader/primaryHeader/menu/links/MenuSubLink';
import { LinkButtonDataProps } from '../../utils';
import { CtaButton, CtaDataProps } from '../CtaButton';
import { AccessibilityIcon } from '../AccessibilityIcon';
import { ItemsWithSeparator } from './ItemsWithSeparator';
import { HeaderRowHeightFactors } from './header.utils';
import { Icon } from '../../../../partials/icon/Icon';
import { SearchIcon } from '../../../../partials/icon/icons';

export interface MenuData {
  links: MenuLinkDataProps[];
  oldSocialSection?: SocialSectionDataProps | null;
  socialSection?: SocialSectionDataProps | null;
  buttons?: Array<LinkButtonDataProps>;
}

export interface RowWithFixedNavProps {
  links: NavigationLink[] | null;
  secondaryLogo?: LogoDataProps | null;
  logo: LogoDataProps | null;
  editions: NavigationLink[] | null;
  menu: MenuData | null;
  videoLogo?: InjectedVideoLogoApiProps;
  pagePath: string;
  editionEndpoint: string;
  oldSocialSection?: SocialSectionDataProps | null | undefined;
  socialSection?: SocialLink[] | null | undefined;
  moreButtonText: string;
  editionsIcon: EditionsIconDataProps | null;
  hideInSmallAndMediumScreens: boolean;
  zIndex: number;
  linkColorTypeActive: string;
  linkColorTypeNormal: string;
  linkColorTypeHover: string;
  linkCurrentColor: string;
  linkFontSizeLarge: TypographySize;
  linkFontSizeMedium: TypographySize;
  linkFontSizeSmall: TypographySize;
  logoHeightInLargeScreen: number;
  logoHeightInMediumScreen: number;
  logoHeightInSmallScreen: number;
  linkDropdownIconColor: string;
  rowHeightInLargeScreen: number;
  rowHeightInMediumScreen: number;
  rowHeightInSmallScreen: number;
  subMenuBackgroundColor: string;
  subMenuLinksFontSize: TypographySize;
  rowBackgroundColor: string;
  rowBoxShadow: string;
  socialLinksColor: string;
  socialLinksHoverColor: string;
  cta: CtaDataProps | null;
  showAccessibilityIcon?: boolean;
  reducedHeaderHeightFactors: HeaderRowHeightFactors;
}

const LOGO_CONTAINER_WIDTH = '163px';
const LOGO_CONTAINER_ADDITIONAL_STYLE_SMALL_SCREEN = { style: { width: LOGO_CONTAINER_WIDTH, textAlign: 'left', overflow: 'hidden' } };
const LOGO_ADDITIONAL_STYLE_SMALL_SCREEN = { style: { maxWidth: '100%' } };

const getStyles = (themeProps: HeaderThemeProps, menuFoldState: AnimationDirectionType, animationDuration: number, hideInSmallAndMediumScreens: boolean, zIndex: number, rowBackgroundColor: string, rowFinalHeightInLargeScreen: number, rowHeightInMediumScreen: number, rowHeightInSmallScreen: number, rowBoxShadow: string, linkFontSizeLarge: TypographySize) => {
  const {
    baseUnit,
    verticalGapInSmallScreen,
    fontSizesStyles,
  } = themeProps;

  return StyleSheet.create({
    headerFirstRow: {
      boxShadow: rowBoxShadow,
      backgroundColor: rowBackgroundColor,
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex,
      position: 'relative',
      transition: `height ${animationDuration}ms ease 0s`,
      [MEDIA_BREAKPOINTS.large]: {
        display: 'flex',
        height: `${rowFinalHeightInLargeScreen}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: hideInSmallAndMediumScreens ? 'none' : 'flex',
        height: `${rowHeightInMediumScreen * baseUnit}px`,
        justifyContent: 'space-between',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: hideInSmallAndMediumScreens ? 'none' : 'flex',
        height: `${rowHeightInSmallScreen * baseUnit}px`,
        padding: `0 ${baseUnit * verticalGapInSmallScreen}px`,
        justifyContent: 'space-between',
      },
    },
    headerFirstRowItemsContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      gap: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        margin: '0 30px 0 60px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        margin: '0 20px 0 40px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        margin: '0',
      },
    },
    fixedNav: {
      [MEDIA_BREAKPOINTS.large]: {
        height: `${getLineHeightBySize(fontSizesStyles, linkFontSizeLarge, 'large')}px`,
        overflow: 'hidden',
        display: 'block',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    subMenuWrapper: {
      position: 'absolute',
      left: 0,
      top: `${rowFinalHeightInLargeScreen / 2}px`,
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    ctaWrapper: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'grid',
      gridAutoFlow: 'column',
      height: '100%',
      width: 'max-content',
      alignItems: 'center',
      gridColumnGap: `${baseUnit}px`,
    },
  });
};

const createMenuStyle = (): AdditionalStyleSheet => {
  return StyleSheet.create({
    style: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-flex',
        marginRight: 0,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'inline-flex',
        marginRight: 0,
      },
    },
  }) as AdditionalStyleSheet;
};

const additionalSearchStyle = (normalColor: string, hoverColor: string) => StyleSheet.create({
  style: {
    stroke: normalColor,
    height: '22px',
    width: '22px',
    ':hover': {
      stroke: hoverColor,
    },
  },
}) as AdditionalStyleSheet;

const getMoreMenuLinks = (invisibleLinks: HTMLElement[], links: NavigationLink[]) => {
  const numLinks = invisibleLinks.length;
  return links.slice(links.length - numLinks);
};

const isSubMenuOpen = (subMenuState: SubMenuReducerProps) => {
  return subMenuState.element !== null;
};

const getInvisibleLinks = (parent: HTMLUListElement | null) => {
  if (!parent) return [];
  const safetyBuffer = 5;
  const baseline = parent.getBoundingClientRect().top + safetyBuffer;
  return Array.from(parent.childNodes).filter(child => {
    return (child as HTMLElement).getBoundingClientRect().top > baseline;
  }) as HTMLElement[];
};

export const RowWithFixedNav: React.FunctionComponent<RowWithFixedNavProps> = props => {
  const {
    videoLogo,
    logo,
    links,
    moreButtonText,
    socialSection,
    oldSocialSection,
    pagePath,
    menu,
    editions,
    editionEndpoint,
    editionsIcon,
    hideInSmallAndMediumScreens,
    zIndex,
    logoHeightInLargeScreen,
    logoHeightInMediumScreen,
    logoHeightInSmallScreen,
    linkFontSizeLarge,
    linkDropdownIconColor,
    linkFontSizeMedium,
    linkFontSizeSmall,
    linkColorTypeActive,
    rowBackgroundColor,
    linkColorTypeHover,
    linkColorTypeNormal,
    rowHeightInLargeScreen,
    linkCurrentColor,
    rowHeightInMediumScreen,
    rowHeightInSmallScreen,
    subMenuBackgroundColor,
    subMenuLinksFontSize,
    rowBoxShadow,
    socialLinksColor,
    socialLinksHoverColor,
    cta,
    showAccessibilityIcon,
    reducedHeaderHeightFactors,
    secondaryLogo,
  } = props;
  const themeProps = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { menuFoldState, animationDuration } = React.useContext<HeaderAnimationContext>(HeaderAnimationContext);
  const {
    baseUnit,
    verticalGapInLargeScreen,
    verticalGapInMediumScreen,
    verticalGapInSmallScreen,
    editionsSeparatorColor,
    editionsMenuBackgroundColor,
    editionsIconHoverColor,
    editionsIconNormalColor,
    editionsMenuWidthInLargeScreen,
    editionsMenuWidthInMediumScreen,
    editionsMenuWidthInSmallScreen,
    fontSizesStyles,
  } = themeProps;
  const navHeightLarge = getLineHeightBySize(fontSizesStyles, linkFontSizeLarge, 'large');
  const rowFinalHeightInLargeScreen = menuFoldState === AnimationDirection.UP ? rowHeightInLargeScreen * reducedHeaderHeightFactors.large * baseUnit : rowHeightInLargeScreen * baseUnit;
  const styles = getStyles(themeProps, menuFoldState, animationDuration, hideInSmallAndMediumScreens, zIndex, rowBackgroundColor, rowFinalHeightInLargeScreen, rowHeightInMediumScreen, rowHeightInSmallScreen, rowBoxShadow, linkFontSizeLarge);
  const menuStyle = createMenuStyle();
  const ulRef = React.useRef<HTMLUListElement>(null);
  const [subMenuState, dispatchMenuState] = useSubMenu();
  const includeSearchIcon = ['90min.com'].find(url => editionEndpoint.includes(url));
  const onSearchClicked = () => {
    const url = new URL(`https://${editionEndpoint}`);
    url.pathname += '/search';
    window.location.href = url.href;
  };

  const [invisibleLinks, setInvisibleLinks] = React.useState<HTMLElement[]>([]);
  const [invisibleLinksRecheckHappened, setInvisibleLinksRecheckHappened] = React.useState(false);
  const [invisibleLinksRecheckIsRequiredBecauseOfFontDisplaySwap, setInvisibleLinksRecheckIsRequiredBecauseOfFontDisplaySwap] = React.useState(false);

  const webFontsContext = React.useContext(WebFontsLoadingFlagContext);

  const closeSubMenu = () => {
    dispatchMenuState({
      data: {
        element: null,
        links: null,
        index: -1,
        linkFontSize: null,
        backgroundColor: '',
      },
    });
  };
  React.useEffect(() => {
    if (ulRef.current !== null) {
      if (menuFoldState === AnimationDirection.NONE) {
        const newInvisibleLinksList = getInvisibleLinks(ulRef.current);
        if (newInvisibleLinksList.length !== invisibleLinks.length) {
          setInvisibleLinks(newInvisibleLinksList);
        }
      }
      if (!invisibleLinksRecheckHappened) {
        setInvisibleLinksRecheckIsRequiredBecauseOfFontDisplaySwap(true);
      }
    }
  }, [ulRef, webFontsContext, baseUnit, verticalGapInLargeScreen, menuFoldState, invisibleLinks, invisibleLinksRecheckHappened]);

  React.useEffect(() => {
    if (invisibleLinksRecheckIsRequiredBecauseOfFontDisplaySwap && !invisibleLinksRecheckHappened) {
      setTimeout(() => {
        if (menuFoldState === AnimationDirection.NONE) {
          const newInvisibleLinksList = getInvisibleLinks(ulRef.current);
          if (newInvisibleLinksList.length !== invisibleLinks.length) {
            setInvisibleLinks(newInvisibleLinksList);
          }
        }
      }, 0.5);
      setInvisibleLinksRecheckHappened(true);
    }
  }, [invisibleLinks.length, invisibleLinksRecheckHappened, invisibleLinksRecheckIsRequiredBecauseOfFontDisplaySwap, menuFoldState]);


  React.useEffect(() => {
    window.addEventListener('scroll', closeSubMenu);

    return () => window.removeEventListener('scroll', closeSubMenu);
  });

  const shouldEditions = editions && editions.length > 0;
  const shouldShowSocialSection = socialSection && socialSection.length > 0;
  const shouldShowSocial = shouldShowSocialSection || (oldSocialSection && oldSocialSection.imageLinks.length > 0);

  const toggleSubMenu = (element: HTMLElement, navigationLinks: NavigationLink[] | null, index: number, linkFontSize: TypographySize, backgroundColor: string) => {
    if (navigationLinks) {
      dispatchMenuState({
        data: {
          element,
          links: navigationLinks,
          index,
          linkFontSize,
          backgroundColor,
        },
      });
    }
  };

  const onToggleFixedNavMenu = (element: HTMLElement, index: number, type: 'link' | 'button') => {
    if (type === 'button') {
      const moreButtonLinks = getMoreMenuLinks(invisibleLinks, links!);
      toggleSubMenu(element, moreButtonLinks, -1, linkFontSizeLarge, rowBackgroundColor);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fixedNavLinks = links![index].children;

      toggleSubMenu(element, fixedNavLinks, index, subMenuLinksFontSize, subMenuBackgroundColor);
    }
  };

  const [registerLinkWithSubMenu, getHoverPlaceholders] = useLinksWithSubMenuHover(onToggleFixedNavMenu, ulRef, rowFinalHeightInLargeScreen, baseUnit);

  const indexOfFirstInvisibleLink = links ? (links.length - invisibleLinks.length) : 0;

  const numberOfItemsWithSeparator = [showAccessibilityIcon, shouldEditions, secondaryLogo, includeSearchIcon].reduce((numberOfItems, itemData) => {
    if (itemData) {
      return numberOfItems + 1;
    }
    return numberOfItems;
  }, 0);

  return (
    <div className={css(styles.headerFirstRow)}>
      <div className={css(styles.headerFirstRowItemsContainer)}>
        {menu ? (
          <Menu
            links={menu.links}
            buttons={menu.buttons}
            additionalStyle={menuStyle}
            oldSocialSection={menu.oldSocialSection}
            socialSection={menu.socialSection}
            showSocialTitle={!secondaryLogo}
          />
        ) : null}
        {videoLogo && logo
          ? (
            <VideoLogo
              {...videoLogo}
              href={logo.href}
              firstRowHeightInSmallScreen={rowHeightInSmallScreen}
              firstRowHeightInMediumScreen={rowHeightInMediumScreen}
              firstRowHeightInLargeScreen={rowHeightInLargeScreen}
              menuAnimation={menuFoldState}
              reduceHeightAnimationDuration={animationDuration}
            />
          )
          : logo && (
          <Logo
            {...logo}
            logoHeightInLargeScreen={logoHeightInLargeScreen}
            logoHeightInMediumScreen={logoHeightInMediumScreen}
            logoHeightInSmallScreen={logoHeightInSmallScreen}
            reduceHeightAnimationDuration={animationDuration}
            additionalStyleImage={LOGO_ADDITIONAL_STYLE_SMALL_SCREEN}
            additionalStyleLink={LOGO_CONTAINER_ADDITIONAL_STYLE_SMALL_SCREEN}
          />
          )}
        <FixedNav
          rowHeight={rowHeightInLargeScreen}
          navHeightLarge={navHeightLarge}
          ulRef={ulRef}
          links={links}
          pagePath={pagePath}
          activeStateColor={linkColorTypeActive}
          hoverStateColor={linkColorTypeHover}
          idleColor={linkColorTypeNormal}
          linkFontSizeLarge={linkFontSizeLarge}
          linkFontSizeMedium={linkFontSizeMedium}
          linkFontSizeSmall={linkFontSizeSmall}
          currentLinkColor={linkCurrentColor}
          linkDropdownIconColor={linkDropdownIconColor}
          subMenuState={subMenuState}
          indexOfFirstInvisibleLink={indexOfFirstInvisibleLink}
          linkBackgroundColor={subMenuState.backgroundColor}
          moreButtonText={moreButtonText}
          registerLinkWithSubMenu={registerLinkWithSubMenu}
        />
      </div>
      <div className={css(styles.headerFirstRowItemsContainer)}>
        {cta && cta.href && cta.text ? (
          <div className={css(styles.ctaWrapper)}>
            <CtaButton {...cta} />
          </div>
        ) : null }
        {shouldShowSocial && (
          <SocialLinks
            socialLinks={socialSection}
            oldSocialSection={oldSocialSection}
            verticalGapInLargeScreen={verticalGapInLargeScreen}
            reduceHeightAnimationDuration={animationDuration}
            socialLinksColor={socialLinksColor}
            socialLinksHoverColor={socialLinksHoverColor}
          />
        )}
        {
          numberOfItemsWithSeparator > 0
            ? (
              <ItemsWithSeparator
                verticalGapInSmallScreen={verticalGapInSmallScreen}
                verticalGapInMediumScreen={verticalGapInMediumScreen}
                verticalGapInLargeScreen={verticalGapInLargeScreen}
                separatorColor={editionsSeparatorColor}
                numberOfItems={numberOfItemsWithSeparator}
              >
                {secondaryLogo && (
                  <Logo
                    {...secondaryLogo}
                    logoHeightInLargeScreen={logoHeightInLargeScreen}
                    logoHeightInMediumScreen={logoHeightInMediumScreen}
                    logoHeightInSmallScreen={logoHeightInSmallScreen}
                    reduceHeightAnimationDuration={animationDuration}
                  />
                )}
                {includeSearchIcon ? <Icon icon={SearchIcon} additionalStyle={additionalSearchStyle(linkColorTypeNormal, linkColorTypeHover)} onClick={onSearchClicked} /> : null}
                {
                  showAccessibilityIcon && (
                    <AccessibilityIcon
                      accessibilityIconHoverColor={editionsIconHoverColor}
                      accessibilityIconNormalColor={editionsIconNormalColor}
                    />
                  )
                }
                {
                  shouldEditions && (
                    <Editions
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      editions={editions!}
                      editionEndpoint={editionEndpoint}
                      editionsMenuWidthInLargeScreen={editionsMenuWidthInLargeScreen}
                      editionsMenuWidthInMediumScreen={editionsMenuWidthInMediumScreen}
                      editionsMenuWidthInSmallScreen={editionsMenuWidthInSmallScreen}
                      fontSizeSmall={subMenuLinksFontSize}
                      fontSizeMedium={subMenuLinksFontSize}
                      fontSizeLarge={subMenuLinksFontSize}
                      colorTypeHover={linkColorTypeHover}
                      colorTypeNormal={linkColorTypeNormal}
                      colorTypeActive={linkColorTypeActive}
                      dropdownIconColor={linkDropdownIconColor}
                      editionsMenuBackgroundColor={editionsMenuBackgroundColor}
                      editionsIcon={editionsIcon}
                      editionsIconHoverColor={editionsIconHoverColor}
                      editionsIconNormalColor={editionsIconNormalColor}
                    />
                  )
                }
              </ItemsWithSeparator>
            )
            : null
          }
      </div>
      <div className={css(styles.subMenuWrapper)} onMouseLeave={closeSubMenu}>
        {getHoverPlaceholders()}
        {
            isSubMenuOpen(subMenuState) && (
              <SubMenu
                rowFinalHeightInLargeScreen={rowFinalHeightInLargeScreen}
                animationDuration={animationDuration}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                links={subMenuState.links!}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parentElement={subMenuState.element!}
                activeStateColor={linkColorTypeActive}
                hoverStateColor={linkColorTypeHover}
                idleColor={linkColorTypeNormal}
                currentLinkColor={linkCurrentColor}
                pagePath={pagePath}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                linkFontSize={subMenuState.linkFontSize!}
                innerLinkFontSize={subMenuLinksFontSize}
                backgroundColor={subMenuState.backgroundColor}
                innerLinksBackgroundColor={subMenuBackgroundColor}
                shouldOffsetLeft={false}
                openLinkIndex={subMenuState.index}
                linkDropdownIconColor={linkDropdownIconColor}
                isParentLinkVisibleOnTheRow
              />
            )
          }
      </div>
    </div>
  );
};
